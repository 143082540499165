/* Help page header starts here */
.help-header {
	padding: 163px 0px 70px 0px;
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.help-header .help-card-wrapper {
	text-align: center;
}
.help-header .help-card-wrapper .search-wrapper {
	display: flex;
	justify-content: center;
}
.help-header .help-card-wrapper p {
	margin-bottom: 30px;
}
.help-header .help-card-wrapper .search-wrapper input {
	flex: 0 0 91%;
	padding: 10px;
	height: 48px;
	border-radius: 5px 0px 0px 5px;
	border: 0px;
}
.help-header .help-card-wrapper .search-wrapper button.submit {
	flex: 0 0 9%;
	background: var(--extra-color-2);
	border: 0px;
	height: 48px;
	border-radius: 0px 5px 5px 0px;
	font-size: 23px;
	color: var(--default-text-color);
}
.help-header .help-card-wrapper .search-wrapper input::placeholder {
	color: #9f9f9f;
	font-family: var(--font-light);
	font-weight: 300;
}
/* Help page header ends here */
.popular-wrapper .popular-cards-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
.popular-wrapper .popular-cards-wrapper .popular-card-wrapper {
	flex: 0 0 47%;
	display: flex;
	border: 1px solid #828496;
	border-radius: 10px;
	padding: 37px 33px 18px 33px;
	margin-right: 31px;
	margin-bottom: 50px;
}
.popular-wrapper
	.popular-cards-wrapper
	.popular-card-wrapper
	.popular-img-wrapper {
	flex: 0 0 20%;
}
.popular-wrapper
	.popular-cards-wrapper
	.popular-card-wrapper
	.popular-content-wrapper {
	flex: 0 0 80%;
}
.popular-wrapper
	.popular-cards-wrapper
	.popular-card-wrapper
	.popular-content-wrapper
	p {
	font-size: 16px;
}
.popular-wrapper
	.popular-cards-wrapper
	.popular-card-wrapper
	.popular-content-wrapper
	h4 {
	margin-bottom: 1.5rem;
}
.cant-find-card-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 22px 30px;
	border: 1px solid #828496;
	border-radius: 10px;
}
.cant-find-card-wrapper .cant-img-wrapper {
	flex: 0 0 12%;
}
.cant-find-card-wrapper .cant-content-wrapper {
	flex: 0 0 70%;
}
.cant-find-card-wrapper .cant-button-wrapper {
	flex: 0 0 18%;
	text-align: end;
	align-self: center;
}
.cant-find-wrapper {
	padding-top: 33px;
	padding-bottom: 100px;
}
.cant-find-wrapper .cant-find-heading-wrapper {
	padding-bottom: 20px;
}
/* responsive starts here */
@media (max-width: 1280px) {
	.popular-wrapper .popular-cards-wrapper .popular-card-wrapper {
		flex: 0 0 46%;
		display: flex;
		border: 1px solid #828496;
		border-radius: 10px;
		padding: 37px 23px 18px 23px;
		margin-right: 31px;
		margin-bottom: 50px;
	}
	.popular-wrapper
		.popular-cards-wrapper
		.popular-card-wrapper
		.popular-content-wrapper
		h4 {
		font-size: 27px;
	}
	.popular-wrapper
		.popular-cards-wrapper
		.popular-card-wrapper
		.popular-img-wrapper {
		flex: 0 0 27%;
	}
	.popular-wrapper
		.popular-cards-wrapper
		.popular-card-wrapper
		.popular-content-wrapper {
		flex: 0 0 73%;
	}
}

@media(max-width:991px){
	.popular-wrapper .popular-cards-wrapper .popular-card-wrapper {
		flex: 0 0 60%;
		margin-right: 0px;
	}

	.cant-find-card-wrapper .cant-img-wrapper {
		flex: 0 0 15%;
	}

}

@media (max-width: 768px) {
	.popular-wrapper .popular-cards-wrapper .popular-card-wrapper {
		flex: 0 0 100%;
		margin-top: 10px;
	}

	.popular-wrapper .popular-cards-wrapper .popular-card-wrapper .popular-img-wrapper {
		flex: 0 0 20%;
	}

	.cant-find-card-wrapper .cant-img-wrapper {
		flex: 0 0 20%;
	}
}

@media (max-width: 575px) {
	.popular-wrapper .popular-cards-wrapper .popular-card-wrapper {
		flex: 0 0 100%;
		margin-right: 0px;
		margin-bottom: 28px;
		margin-top: 23px;
		flex-direction: column;
		text-align: center;
	}

	.cant-find-card-wrapper {
		flex-direction: column;
		text-align: center;
	}

	.cant-find-card-wrapper .cant-img-wrapper {
		flex: 0 0 12%;
		margin-bottom: 30px;
	}

	.popular-wrapper
	.popular-cards-wrapper
	.popular-card-wrapper
	.popular-content-wrapper
	h4 {
    margin-bottom: 1rem;
	font-size: 19px;
}

.help-header {
    padding: 163px 0px 20px 0px;
}

.cant-find-wrapper {
    padding-top: 20px;
    padding-bottom: 60px;
}
.popular-wrapper .popular-cards-wrapper .popular-card-wrapper .popular-img-wrapper {
    flex: 0 0 24%;
    margin-right: 0;
    margin-bottom: 30px;
}
}

/* responsive ends here */
