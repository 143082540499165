section.pricing-section {
	padding: 137px 0px 53px 0px;
	background-image: url("../img/login-bg.png");
	background-position: top left;
	background-repeat: no-repeat;
}
section.pricing-section .pricing-start .pricing-top-text {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

section.pricing-section .pricing-start .pricing-top-text .headings h1 {
	line-height: 1;
	font-size: 61px;
}

section.pricing-section .pricing-start .pricing-top-text .register-option a h4 {
	font-size: 26px;
	margin-bottom: 0px;
	/* color: var(--extra-color-2); */
	box-shadow: inset 0 0 0 0 var(--extra-color-2);
	color: var(--extra-color-2);
	padding: 0 0.25rem;
	margin: 0 -0.25rem;
	transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	line-height: 1.5;
}
section.pricing-section
	.pricing-start
	.pricing-top-text
	.register-option
	a
	h4:hover {
	color: #fff;
	box-shadow: inset 200px 0 0 0 var(--extra-color-2);
}
section.pricing-section .pricing-start .pricing-top-text .register-optio span {
	font-size: 16px;
	color: var(--default-text-color);
}

section.pricing-section .pricing-start .pricing-top-text .headings h4 {
	margin-bottom: 0px;
}

section.pricing-section .pricing-start .pricingdata {
	padding-top: 120px;
}

section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box
	.data-heading
	h4 {
	font-size: 25px;
	line-height: 1;
}

section.pricing-section .pricing-start .pricingdata .pricing-box .data-heading {
	min-height: 112px;
}

section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.simple
	.data-heading {
	padding: 33px 5px 5px;
}

section.pricing-section .pricing-start .pricingdata .pricing-box .pricing-list {
	display: flex;
	flex-direction: column;
}
section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box
	.pricing-list
	.single-price {
	padding: 13px 16px;
	font-size: 16px;
	min-height: 59px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
section.pricing-section .pricing-start .pricingdata .pricing-box .pricing-list .single-price.pricing_second {
    min-height: 71px;
}
section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box
	.pricing-list
	.single-price:nth-child(odd) {
	background-color: var(--light-background-color);
}
section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.blue
	.pricing-list
	.single-price:nth-child(odd) {
	background-color: #00000021;
}
section.pricing-section .pricing-start .pricingdata .pricing-box.blue .pricing-list .single-price .single_span {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
section.pricing-section .pricing-start .pricingdata .pricing-box.blue {
	position: relative;
	padding: 0px 0px 33px;
	background-color: var(--extra-color-2);
	border-radius: 40px;
}
section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.blue
	.data-heading {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.blue
	.data-heading
	h6 {
	margin-top: auto;
	text-align: center;
	line-height: 1;
}
section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.blue
	.data-heading
	h6
	p {
	color: #fff;
}
section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.blue
	.pricing-list
	.single-price {
	text-align: center;
	font-size: 20px;
	font-family: var(--font-bold);
}
/* ::-webkit-scrollbar {
	display: none;
} */
section.pricing-section .pricing-start .pricingdata .pricing-box.blue .buy-now {
	text-align: center;
}

section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.blue
	.buy-now
	.btn {
	background-color: var(--extra-color-1);
	padding: 6px 23px;
}
section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.blue
	.buy-now
	.btn:hover {
	background: var(--extra-color-3);
}
section.pricing-section
	.pricing-start
	.pricingdata
	.pricing-box.blue
	.data-heading
	h6.riban-best {
	background-image: url("../img/riban.png");
	display: inline-block;
	width: fit-content;
	padding: 11px 39px 9px;
	background-position: center;
	margin: auto;
	font-size: 14px;
	background-size: 100%;
}
.pricing-rules .points-start {
    padding: 28px 67px 35px;
    background: var(--extra-color-2);
    border-radius: 36px;
    margin-top: 32px;
}
.pricing-rules .points-start ul {
    margin: 0;
}
.pricing-rules .points-start p {
	margin-top: 7px;
	margin-bottom: 0px;
	color: var(--default-text-color);
	font-family: var(--font-italic);
}
.terms-condition {
	margin-top: 46px;
}
.terms-condition h3 {
	font-size: 32px;
}
.terms-condition .text p {
	margin-bottom: 10px;
	line-height: 1.9;
}
@media (max-width: 1400px) {
	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box
		.pricing-list
		.single-price {
		font-size: 14px;
	}
}
@media (max-width: 1200px) {
	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box
		.data-heading
		h4 {
		font-size: 22px;
	}
	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box.blue
		.data-heading
		h6 {
		font-size: 16px;
	}
	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box.blue
		.data-heading
		h6.riban-best {
		font-size: 13px;
	}
	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box
		.pricing-list
		.single-price {
		font-size: 11px;
	}
	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box.blue
		.pricing-list
		.single-price {
		font-size: 12px;
		padding: 13px 12px;
	}
	section.pricing-section .pricing-start .pricingdata .data-heading h2 {
		font-size: 28px;
	}
	section.pricing-section .pricing-start .pricingdata .data-heading h4 {
		font-size: 20px;
	}
}
@media (max-width: 991px) {
	section.pricing-section .pricing-start .pricingdata {
		max-width: 100%;
		overflow: scroll;
		padding-top: 50px;
	}

	section.pricing-section .pricing-start .pricingdata .thetable {
		min-width: 950px;
	}
}
@media (max-width: 576px) {
	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box.blue
		.pricing-list
		.single-price {
		font-size: 13px;
	}

	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box
		.data-heading
		h4 {
		font-size: 16px;
	}

	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box.blue
		.data-heading
		h6 {
		font-size: 14px;
	}

	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box.blue
		.data-heading
		h6.riban-best {
		font-size: 10px;
	}

	section.pricing-section .pricing-start .pricingdata .thetable {
		min-width: 824px;
	}

	section.pricing-section
		.pricing-start
		.pricingdata
		.pricing-box.blue
		.data-heading
		h6.riban-best {
		padding: 11px 26px 9px;
		background-repeat: no-repeat;
	}
	.terms-condition h3 {
		font-size: 28px;
	}

	section.pricing-section .pricing-start .pricingdata {
		padding-top: 50px;
	}
}
@media (max-width: 480px) {
	section.pricing-section .pricing-start .pricing-top-text {
		flex-direction: column;
	}
	section.pricing-section .pricing-start .pricing-top-text {
		flex-direction: column;
		align-items: flex-start;
	}
	.pricing-section .pricing-start .pricing-top-text .register-option {
		margin-top: 27px;
	}
	section.pricing-section .pricing-start .pricingdata .data-heading h2 {
		font-size: 25px;
		line-height: 2;
	}
	section.pricing-section .pricing-start .pricing-top-text .headings h1 {
		font-size: 46px;
	}
	.pricing-rules .points-start {
		padding: 28px 15px 20px;
	}
	.pricing-rules .points-start ul li {
		margin-bottom: 20px;
	}
}
