.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4,
.line-clamp-5 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.line-clamp-1 {
	-webkit-line-clamp: 1;
}
.line-clamp-2 {
	-webkit-line-clamp: 2;
}
.line-clamp-3 {
	-webkit-line-clamp: 3;
}
.line-clamp-4 {
	-webkit-line-clamp: 4;
}
.line-clamp-5 {
	-webkit-line-clamp: 5;
}
.Licensing-sec {
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top center;
}
.release-sec {
	padding-top: 149px;
	padding-bottom: 70px;
}
.release-sec .release-table-wrapper th {
	font-size: 20px;
	color: var(--default-text-color);
	padding: 25px 10px;
	border: 0px;
	font-family: var(--font-bold);
}
.release-table-wrapper td.title-row h6 {
	color: var(--default-text-color);
	font-size: 18px;
	font-weight: 400 !important;
	font-family: var(--font-default);
	margin: 0px;
}
.release-sec .release-table-wrapper tbody tr {
	background: #031637;
	border: 1px solid #30334f;
}
.release-sec .release-table-wrapper table {
	border-collapse: separate;
	border-spacing: 0 15px;
}
.release-table-wrapper tbody td:first-child {
	border-right: 0px;
	padding: 23px 23px;
}
.release-table-wrapper tbody td:last-child {
	border-left: 0px;
}
.release-table-wrapper tbody td {
	vertical-align: middle;
	padding: 23px 10px;
	border-left: 1px solid #30334f;
	border-top: 1px solid #30334f;
	border-right: 1px solid #30334f;
}
.release-table-wrapper tbody td.actions-wrap .btn-wrap {
	display: flex;
}
.release-table-wrapper tbody td.actions-wrap .btn-wrap a,
.release-table-wrapper tbody td.actions-wrap .btn-wrap button {
	color: var(--extra-color-2);
	font-family: var(--font-default);
	font-size: 17px;
	padding: 0px 9px;
	border-right: 1px solid;
}
.release-table-wrapper tbody td.actions-wrap .btn-wrap a:last-child {
	border: 0px;
}
/* responsive starts here */
@media (max-width: 1024px) {
	.release-table-wrapper td.title-row h6 {
		font-size: 16px;
	}
}

@media (max-width: 575px) {
	.release-sec {
		padding-top: 30px;
		padding-bottom: 40px;
	}
}

@media (max-width: 480px) {
	.release-table-wrapper tbody td:first-child {
		padding: 7px 10px;
	}
	.release-table-wrapper td.title-row h6 {
		font-size: 14px;
	}
	.release-table-wrapper tbody td.actions-wrap .btn-wrap a {
		font-size: 14px;
	}
}
/* responsive ends here */
