.artist-masnary {
	padding-bottom: 100px;
	padding-top: 50px;
}
.artist-masnary .masonry_box .image_box .bottom-bar {
	padding: 16px;
}
.artist-masnary .masonry_box .image_box .bottom-bar {
	bottom: -18px;
}
.artist-masnary .masonry_box .image_box .bottom-bar h3 {
	font-size: 30px;
}
.resources_clamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.artist-masnary .masonry_box {
	padding-top: 23px;
}

/* Mobile Responsive  */
@media (max-width: 575px) {
	.artist-masnary {
		padding-bottom: 40px;
		padding-top: 30px;
	}
}
/* Mobile Responsive  */
