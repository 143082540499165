.faq {
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top center;
	padding: 80px 0px;
}
.faq .heading-row {
	margin-bottom: 34px;
}
.faq-wrapper {
	border: 1px solid #777575;
	border-radius: 7px;
}
.faq-wrapper .accordion-item {
	background: transparent;
	border: 0px;
	border-bottom: 1px solid #fff;
}
.faq-wrapper .accordion-item:last-child {
	border: 0;
}

.faq-wrapper .accordion-item h2 {
	background: transparent;
	border: 0px;
}
.faq-wrapper .accordion-item button.accordion-button {
	background: transparent;
	border-bottom: 1px solid #777575 !important;
	color: var(--default-text-color);
	font-family: var(--font-bold);
	font-size: 18px;
	padding: 23px 20px;
}
button.accordion-button p {
	margin: 0;
	font-size: 18px;
	color: #fff;
	font-family: var(--font-bold);
}
.faq-wrapper .accordion-item .accordion-collapse .accordion-body {
	border: 0px !important;
	color: var(--default-text-color);
	font-family: var(--font-light);
	font-weight: 300;
	font-size: 14px;
}
.faq .accordion-button::after {
	content: "";
	background: url("../img/plus.png");
	background-size: contain;
	height: 12px;
	width: 12px;
}
.faq .accordion-button:not(.collapsed)::after {
	background: url("../img/miuns.png");
	content: "";
	height: 4px;
	width: 12px;
	position: relative;
}

/* Tablet Responsive  */
@media (max-width: 991px) {
	.faq {
		padding: 110px 0px !important;
	}
	.faq-wrapper .accordion-item button.accordion-button {
		font-size: 16px;
		padding: 23px 20px;
		line-height: 1.5;
	}
	button.accordion-button p {
		font-size: 16px;
		padding: 23px 20px;
		line-height: 1.5;
	}
}
/* Tablet Responsive  */

/* Mobile Responsive  */
@media (max-width: 575px) {
	.faq {
		padding: 100px 0px 50px !important;
	}
	.faq-wrapper .accordion-item button.accordion-button {
		font-size: 13px;
		padding: 23px 10px;
		line-height: 1.5;
	}
	button.accordion-button p {
		font-size: 12px;
		padding: 0px 0px;
		line-height: 1.5;
	}
}
/* Mobile Responsive  */
