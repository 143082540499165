.not__found-wrap {
	max-width: 800px;
	margin: auto;
	padding: 5rem 0.5rem;
	text-align: center;
}

.not__found-wrap h1 {
	font-family: "hasnon-bold";
	color: var(--first-color);
}

main.not__found-wrap img {
	width: 200px;
	object-fit: cover;
	margin-top: 23px;
}

.not__found-wrap h1 {
	font-family: "hasnon-bold";
	color: var(--first-color);
	margin-top: 50px;
	font-size: 46px;
}

.about-banner-text h2 {
	font-size: 35px;
}
