.contact {
  padding-top: 221px;
  padding-bottom: 150px;
  background-image: url("../img/login-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}
.contact .contact-icon-wrapper .single-contact-wrapper {
  display: flex;
  align-items: center;
}
.contact .contact-us-card-wrapper {
  padding: 60px 130px;
  border: 1px solid #ffffff70;
  border-radius: 6px;
}
.contact .contact-us-card-wrapper .contact-heading-wrapper {
  text-align: center;
}
.contact
  .contact-icon-wrapper
  .single-contact-wrapper
  .single-contact-content-wrapper {
  flex: 0 0 80%;
}
.contact
  .contact-icon-wrapper
  .single-contact-wrapper
  .single-contact-img-wrapper {
  margin-right: 22px;
  flex: 0 0 20%;
}
.contact
  .contact-icon-wrapper
  .single-contact-wrapper
  .single-contact-content-wrapper
  h5 {
  font-size: 20px;
  line-height: 1;
  font-family: var(--font-light);
  font-weight: 300;
}
.contact
  .contact-icon-wrapper
  .single-contact-wrapper
  .single-contact-content-wrapper
  h4 {
  font-family: var(--font-heading);
  font-size: 22px;
  margin: 0px;
}
.contact .contact-us-card-wrapper .contact-icon-wrapper {
  margin-top: 42px;
  margin-bottom: 35px;
}
.contact .contact-us-card-wrapper .contact-form-wrapper .form-group {
  margin-bottom: 15px;
}
.contact .contact-us-card-wrapper .contact-form-wrapper .form-group label {
  margin-bottom: 10px;
  font-family: var(--font-semibold);
}
.contact .contact-us-card-wrapper .contact-form-wrapper .form-group label span {
  color: var(--extra-color-2);
}
.contact .contact-us-card-wrapper .contact-form-wrapper .form-group input {
  height: 47px;
  border-radius: 4px;
  background: #141737;
  color: #bbbbbb;
  font-family: var(--font-light);
  font-size: 12px;
  margin-bottom: 20px;
}
.contact .contact-us-card-wrapper .contact-form-wrapper .form-group textarea {
  border-radius: 4px;
  background: #141737;
  color: #bbbbbb;
  font-family: var(--font-light);
  font-size: 12px;
  margin-bottom: 20px;
}
.contact .contact-us-card-wrapper .contact-form-wrapper button.btn {
  background: var(--extra-color-3);
  border: 1px solid var(--extra-color-3);
}
.contact .contact-us-card-wrapper .contact-form-wrapper button.btn:hover {
	background: var(--extra-color-2) !important;
	border-color:var(--extra-color-2) !important;
  }
/* Contact us modal starts here */
.contactmodal .modal-header .btn-close {
  opacity: 1;
  filter: brightness(0) invert(1);
}
.contactmodal .modal-dialog .modal-content {
  background: transparent;
  border: 1px solid #828496;
}
.contactmodal .modal-dialog .modal-header {
  border-bottom: 0px;
}
.contactmodal .modal-dialog .modal-content .contact-form-modal-content-wrapper {
  text-align: center;
  padding-bottom: 30px;
}
.contactmodal {
  background: #05092ceb;
  display: flex !important;
}
.contactmodal .modal-dialog {
  max-width: 653px !important;
  align-self: center;
}
.contactmodal
  .modal-dialog
  .modal-content
  .contact-form-modal-content-wrapper
  p {
  font-family: var(--font-light);
  font-size: 20px;
  color: var(--extra-color-2);
}
/* Contact us modal ends here */
/* rtesponsive starts here */
@media (max-width: 1366px) {
  .contact
    .contact-icon-wrapper
    .single-contact-wrapper
    .single-contact-content-wrapper
    h4 {
    font-size: 17px;
  }
}
@media (max-width: 1024px) {
  .contact .contact-us-card-wrapper {
    padding: 60px 50px;
  }
  .contact {
    background-size: 100% 19%;
  }
}
@media (max-width: 800px) {
  .contact .contact-icon-wrapper .single-contact-wrapper {
    margin-bottom: 38px;
  }
  .contact-icon-wrapper .col-lg-6:last-child .single-contact-wrapper {
    margin-bottom: 0px;
  }
}

@media(max-width:575px){
  .contact .contact-us-card-wrapper .contact-form-wrapper button.btn {
    display: block;
    width: 100%;
}
}

@media (max-width: 480px) {
  .contact .contact-us-card-wrapper {
    padding: 60px 17px;
  }
  .contact .contact-us-card-wrapper .contact-heading-wrapper p br {
    display: none;
  }
  .contact {
    background-size: 100% 15%;
  }
}

/* responsive ends here */
