.profile .my-account-wrapper {
	padding: 50px 50px;
	border: 1px solid #828496;
	border-radius: 1px;
}
.profile .my-account-wrapper .my-account-heading-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 34px;
}
.profile .my-account-wrapper .my-account-heading-wrapper h3 {
	margin-bottom: 0px;
}
.profile .my-account-wrapper .my-account-heading-wrapper button {
	margin-top: 0px;
}
.profile .my-account-wrapper .form-group input[type="password"] {
	width: 100px;
}
.profile .my-account-wrapper .form-group {
	margin-bottom: 3rem;
}
.profile .my-account-wrapper .form-group label {
	display: block;
	margin-bottom: 1rem;
	font-family: var(--font-semibold);
}

.profile .my-account-wrapper .form-group input {
	background: transparent;
	border: 0px;
	color: var(--default-text-color);
	width: 100%;
}
.profile .my-account-wrapper .form-group label span {
	color: var(--extra-color-2);
}
.profile .my-account-wrapper .form-group input::placeholder {
	color: #bbbbbb;
	font-family: var(--font-default);
}
.profile .my-account-wrapper .pass-btn-field-wrapper {
	display: flex;
}
.profile .my-account-wrapper .pass-btn-field-wrapper button.edit-pass {
	background: transparent;
	border: none;
	font-size: 11px;
	color: var(--extra-color-2);
	font-family: var(--font-default);
	margin-top: -3px;
}
.profile .my-account-wrapper .form-group input.edit,
.profile .my-account-wrapper .form-group select.edit {
	height: 41px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	border: 1px solid #ced4da;
	padding: 0px 10px;
	width: 100%;
}
.profile .my-account-wrapper .form-group input#edit,
.profile .my-account-wrapper .form-group select#edit {
	height: 41px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	border: 1px solid #ced4da;
	padding: 0px 10px;
	width: 100%;
	margin-bottom: 11px;
}
/* responsive starts here */
@media (max-width: 1024px) {
	.profile .my-account-wrapper .pass-btn-field-wrapper button.edit-pass {
		font-size: 10px;
	}
	.profile .my-account-wrapper .form-group label {
		font-size: 13px;
	}
	.profile .my-account-wrapper button.btn {
		font-size: 14px;
	}
}

@media(max-width:991px){
	.profile .my-account-wrapper button.btn {
		display: block;
		width: 100%;
	}
}

@media (max-width: 768px) {
	.profile .my-account-wrapper {
		padding: 30px 19px;
	}
	.profile .my-account-wrapper .form-group .col-lg-4:first-child {
		margin-bottom: 1rem;
	}
	.profile .my-account-wrapper .form-group {
		margin-bottom: 1rem;
	}
	.profile .my-account-wrapper .form-group .col-lg-4 {
		margin-top: 28px;
	}
	.profile .my-account-wrapper .my-account-heading-wrapper {
		flex-direction: column;
		align-items: flex-start;
	}
	.profile .my-account-wrapper .my-account-heading-wrapper button {
		margin-top: 29px;
		width: 100%;
	}
}
/* responsive ends here */
