.artist-news-sec {
	background: transparent;
}
.artist-news-sec .testimonials-wrapper h1 {
	font-size: 40px;
	line-height: 1.3;
}
.artist-news-sec .blog_box .blog-detail h2 {
    font-size: 25px;
    font-weight: 700;
	overflow: unset;
	-webkit-line-clamp:unset
}
.artist-news-sec .blog_box .blog-detail p {
    opacity: 80%;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.artist-news-sec .blog_box .blog-detail .artist_description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.blog-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.news-detail-img-wrapper figure img {
	width: 100%;
	object-fit: cover;
}
.artist-news-sec .blog_box .blog-detail a {
	color: var(--extra-color-2);
	font-size: 16px;
	transition: 0.8s all;
}
.artist-news-sec .blog_box .blog-detail {
    height: 241px;
}
.artist-news-sec .blog_box .blog-detail a:hover {
    color: #ffff;
}
.artist-news-sec .blog_box .blog-detail button {
	color: var(--extra-color-2);
	font-size: 16px;
	border: none;
	background: none;
	cursor: pointer;
	text-align: left;
}
.news-detail {
	padding-top: 70px;
	padding-bottom: 70px;
}

.news-detail img {
	height: 315px;
	object-fit: cover;
}
.news-detail p {
	font-size: 15px;
	margin-bottom: 32px;
	margin-top: 20px;
	line-height: 24px;
	opacity: 80%;
}
.news-detail .news-detail-heading-wrapper h2 {
	margin-top: 40px;
}
.news-detail .ps-lg-5 {
	padding-left: 30px !important;
}
.go-to-previous-page {
	margin-top: 54px;
}

.go-to-previous-page a {
	font-family: var(--font-bold);
	background: var(--extra-color-3);
	padding: 9px 20px;
	cursor: pointer;
}
@media (max-width: 1440px) {
	.news-detail .ps-lg-5 {
		padding-left: 30px !important;
	}
}
@media (max-width: 1366px) {
	.news-detail .ps-lg-5 {
		padding-left: 20px !important;
	}
}
@media (max-width: 768px) {
	.news-detail .ps-lg-5 {
		padding-left: 0px !important;
	}
}
