.code-sec {
	padding-top: 70px;
	padding-bottom: 70px;
}
.code-sec .conduct-heading-wrapper {
	padding-bottom: 30px;
}
.code-sec .conduct-heading-wrapper h2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.code-sec .conduct-content-wrapper h4 {
	font-size: 25px;
	font-weight: 600;
	font-family: var(--font-semibold);
}
.code-sec .conduct-content-wrapper p {
	letter-spacing: 1px;
	font-size: 16px;
	font-weight: 300 !important;
	margin-top: 20px;
}
.code-sec .conduct-content-wrapper {
	margin-bottom: 43px;
}

/* Mobile Responsive  */
@media(max-width:575px){
	.code-sec {
		padding-top: 30px;
		padding-bottom: 40px;
	}
}
/* Mobile Responsive  */
