.Artist .my-account-wrapper {
	padding: 50px 50px;
	border: 1px solid #828496;
	border-radius: 8px;
}
.Artist .my-account-wrapper .my-account-heading-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 34px;
}
.Artist .my-account-wrapper .form-group {
	margin-bottom: 3rem;
}
.Artist .my-account-wrapper .form-group label {
	display: block;
	margin-bottom: 1rem;
	font-family: var(--font-semibold);
}
.Artist .my-account-wrapper .form-group input {
	background: transparent;
	border: 0px;
	width: 100%;
	color: var(--default-text-color);
}
.Artist .my-account-wrapper .form-group label span {
	color: var(--extra-color-2);
}
.Artist .my-account-wrapper .form-group input::placeholder {
	color: #bbbbbb;
	font-family: var(--font-default);
}
.Artist .my-account-wrapper .pass-btn-field-wrapper {
	display: flex;
	justify-content: left;
}
.Artist .my-account-wrapper .pass-btn-field-wrapper button.edit-pass {
	background: transparent;
	border: none;
	font-size: 10px;
	color: var(--extra-color-2);
	font-family: var(--font-default);
}
.Artist .my-account-wrapper .form-group input.edit,
.Artist .my-account-wrapper .form-group select.edit {
	height: 41px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	border: 1px solid #ced4da;
	padding: 0px 10px;
	width: 100%;
}
.Artist .my-account-wrapper .form-group input#edit,
.Artist .my-account-wrapper .form-group select.edit {
	height: 41px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	border: 1px solid #ced4da;
	padding: 0px 10px;
	width: 100%;
}
.Artist
	.my-account-wrapper
	.form-group
	.pass-btn-field-wrapper
	input[type="password"] {
	width: 100px !important;
}
/* responsive starts here */

@media(max-width:991px){
	.Artist .my-account-wrapper .form-group .col-lg-4 {
		margin-top: 28px;
	}
	.Artist .my-account-wrapper .form-group:first-child {
		margin-bottom: 0px;
	}
	.Artist .my-account-wrapper .form-group {
		margin-bottom: 1rem;
	}
	
}

@media (max-width: 768px) {
	.Artist .pyment-extra-heading {
		padding-bottom: 3px;
	}

	.Artist .my-account-wrapper {
		padding: 30px 20px;
	}
	.Artist .my-account-wrapper .form-group .col-lg-4 {
		margin-top: 28px;
	}
	.Artist .my-account-wrapper .my-account-heading-wrapper {
		flex-direction: column;
		align-items: flex-start;
	}
	.Artist .my-account-wrapper .my-account-heading-wrapper button {
		margin-top: 29px;
		width: 100%;
	}
	.Artist .artistSidebar {
		margin-bottom: 42px;
	}
	.Artist .my-account-form-wrapper button.btn {
		width: 100%;
	}
}
@media (max-width: 480px) {
	.Artist .my-account-wrapper {
		padding: 30px 20px;
	}
	.Artist .my-account-wrapper .form-group {
		margin-bottom: 1rem;
	}
	.Artist .my-account-wrapper .form-group .col-lg-4 {
		margin-top: 15px;
	}
	.Artist .my-account-wrapper .my-account-heading-wrapper {
		flex-direction: column;
		align-items: flex-start;
	}
	.Artist .my-account-wrapper .my-account-heading-wrapper button {
		margin-top: 29px;
		width: 100%;
	}
	.Artist .artistSidebar {
		margin-bottom: 42px;
	}
	.Artist .my-account-form-wrapper button.btn {
		width: 100%;
	}
}
/* responsive ends here */
