/* Agreement Css Start Here */
header.inner-page-agreement {
	background: #05092c !important;
	position: absolute !important;
}
.agreement_sec {
	padding-top: 137px;
	padding-bottom: 50px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}
.agreement_sec .table_content_list li a {
	font-family: var(--font-light);
	font-size: 15px;
	line-height: 1.5;
	color: var(--text-color);
}
.agreement_sec .content-box .points ul {
	padding: 0;
	margin: 0;
	list-style: none;
	font-family: var(--poppin-light);
	color: #00bbff;
}
.agreement_sec .content-box .points ul li {
	margin-bottom: 9px;
	display: flex;
}
.agreement_sec .content-box .points ul li span {
	display: block;
	min-width: 35px;
	color: #fff;
}
.agreement_sec .content-box .points {
	padding: 50px 0;
}
.agreement_sec .content-box .description-box {
	padding-bottom: 50px;
}
.agreement_sec .content-box .description-box h3 {
	margin-bottom: 20px;
}
.agreement_sec .content-box .description-box p {
	line-height: 27px;
	font-family: "Vietnam-reg";
	font-size: 15px;
}
/* Agreement Css End Here */
