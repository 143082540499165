.login-sec {
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
	padding: 205px 0px 300px 0px;
}
.login-sec .login-card-wrapper {
	padding: 60px 55px;
	border: 1px solid #ffffff70;
	border-radius: 6px;
}
.login-sec .login-card-wrapper .login-heading-wrapper {
	text-align: center;
}
.login-sec .login-card-wrapper .login-heading-wrapper p {
	font-size: 19px;
}
.login-sec .login-card-wrapper .login-form-wrapper .form-group {
	margin-bottom: 15px;
}
.login-sec .login-card-wrapper .login-form-wrapper .form-group label {
	margin-bottom: 10px;
	font-family: var(--font-semibold);
}
.setFonts {
	font-family: var(--font-montserrat-reg);
	font-size: 13px;
}
.custom_check_box1 {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 15px;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	font-size: 13px;
	color: #eddcdc;
	text-align: start;
}
.custom_check_box1 input:checked ~ .checkmark1:after {
	display: block;
}
.checkmark1:after {
	content: "";
	display: none;
	position: absolute;
}
.custom_check_box1 .checkmark1:after {
	border: solid #fff;
	border-width: 0 2px 2px 0;
	height: 11px;
	left: 5px;
	top: 1px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 5px;
}
.custom_check_box1 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.custom_check_box1 .checkmark1 {
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 19px;
	background-color: #eee;
	border: 1px solid #959595;
	border-radius: 5px;
	background: transparent;
}
.custom_check_box1 input:checked ~ .checkmark1 {
	background: transparent;
}

.custom_check_box1 input:checked ~ .checkmark1:after {
	display: block;
}

/* Style the checkmark/indicator */
.custom_check_box1 .checkmark1:after {
	left: 6.2px;
	top: 1px;
	width: 5px;
	height: 11px;
	border: solid #e1eae6;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
.login-sec
	.login-card-wrapper
	.login-form-wrapper
	.form-group
	.pass-label-wrapper {
	display: flex;
	justify-content: space-between;
}
.login-sec .login-card-wrapper .login-form-wrapper .form-group label span {
	color: var(--extra-color-2);
}
.login-sec
	.login-card-wrapper
	.login-form-wrapper
	.form-group
	.pass-label-wrapper
	a {
	color: var(--extra-color-2);
}
.login-sec .login-card-wrapper .login-form-wrapper input.form-control {
	height: 47px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	margin-bottom: 20px;
}
.login-sec .login-card-wrapper button.btn.form-control {
	height: 53px;
}
.login-sec .login-card-wrapper button.btn.form-control:hover {
	background: var(--extra-color-2);
}
.login-sec .login-card-wrapper .login-heading-wrapper a {
	color: var(--extra-color-2);
}
/* responsive starts here */
@media (max-width: 1024px) {
	.login-sec {
		background-size: 100% 19%;
	}
}
@media (max-width: 480px) {
	.login-sec .login-card-wrapper {
		padding: 60px 31px;
	}
}
@media (max-width: 375px) {
	.login-sec .login-card-wrapper {
		padding: 27px 8px;
	}
	.login-sec .login-card-wrapper a.btn.form-control.res {
		height: 56px;
		line-height: 2;
		font-size: 16px;
	}
}
