/* Image Sec Css Start Here */
.image_sec {
	padding-top: 137px;
	padding-bottom: 150px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top;
}
.set_video_size video {
	object-fit: cover !important;
	width: 100% !important;
	height: 400px !important;
	border-radius: 8px !important;
}
.set_video_size img {
	object-fit: cover !important;
	width: 100% !important;
	height: 400px !important;
	border-radius: 8px !important;
	object-position: top !important;
}
.set_images video {
	object-fit: cover;
	width: 100%;
	height: 414px;
	border-radius: 8px;
}
.image_sec .topbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}
.image_sec .topbar .filter-div {
	display: flex;
	align-items: center;
	margin-top: 30px;
}
.image_sec .flter-box .cancel {
	position: absolute;
	right: 15px;
	background: transparent;
	border: 0;
	top: 15px;
	padding: 0;
	z-index: 9;
}
.image_sec .topbar .filter-div ul.filters {
	padding: 0 0;
	list-style: none;
	margin: 0;
}
.image_sec .topbar .filter-div ul.filters li {
	display: inline-block;
	background: #004c6661;
	color: #fff;
	font-size: 12px;
	padding: 12px 30px;
	margin-right: 20px;
	border: 1px solid #cecece63;
	position: relative;
}
.image_sec .topbar .filter-div ul.filters li span.cancel {
	position: absolute;
	top: 0px;
	right: 8px;
	cursor: pointer;
}
.image_sec .topbar .filter-div .clear {
	color: #00bbff;
	background: transparent;
	padding: 0;
	border: 0;
	font-size: 14px;
	font-family: "Vietnam-reg";
}
.image_sec .right-bar {
	text-align: end;
}
.image_sec .right-bar .title h3 {
	font-size: 30px;
	line-height: 30px;
	color: #00bbff;
}
.image_sec .right-bar .title h3 {
	background-image: linear-gradient(
		to right,
		#00bbff,
		#00bbff 50%,
		var(--extra-color-3) 50%
	);
	/* background-color: #00bbff; */
	background-size: 200% 100%;
	background-position: -200%;
	display: inline-block;
	padding: 5px 0;
	position: relative;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	transition: all 0.3s ease-in-out;
}
.image_sec .right-bar .title h3:before {
	content: "";
	background-color: #00bbff;
	display: block;
	position: absolute;
	bottom: -3px;
	left: 0;
	width: 0;
	height: 3px;
	transition: all 0.3s ease-in-out;
}

.image_sec .right-bar .title h3:hover {
	background-position: 0;
}

.image_sec .right-bar .title h3:hover::before {
	width: 100%;
}
.image_sec .right-bar .title p {
	font-size: 18px;
}
.image_sec .right-bar .title {
	padding-bottom: 10px;
}
.image_sec .right-bar .button-group .filter {
	background: #004c66;
	border: 0;
	padding: 14px 35px;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	font-family: "Vietnam-reg";
}
.image_sec .right-bar .button-group .filter svg {
	margin-right: 15px;
}
.image_sec .flter-box .topbar {
	justify-content: left;
	display: flex;
}
.image_sec .flter-box {
	text-align: left;
	width: 26%;
	position: absolute;
	left: unset;
	right: 0%;
	top: 110%;
}
.image_sec .flter-box .topbar button {
	border-right: 0;
	padding-right: 0;
}
.image_sec .flter-box .radio-box .head {
	padding: 0 25px;
}
.image_sec .flter-box .images-box ul.list li {
	padding-left: 50px;
}
.image_sec .flter-box .radio-box.tags ul {
	padding: 0 0 0 30px;
	margin: 0;
	list-style: none;
}
.image_sec .flter-box .radio-box .form-group {
	padding: 0 20px;
}
.masonry_box {
	padding-top: 60px;
}
.masonry_box .showing-results {
	padding-bottom: 20px;
}
.masonry_box .image_box {
	overflow: hidden;
	position: relative;
}
.masonry_box .image_box:before {
	content: "";
	background: linear-gradient(180deg, transparent 0, #05092c82 30%, #05092ced);
	width: 100%;
	height: 65px;
	bottom: 0px;
	position: absolute;
	border-radius: 0px 0px 7px 7px;
	opacity: 0;
	transition: 0.6s;
	z-index: 2;
}
.masonry_box .image_box:hover:before {
	opacity: 1;
}
.masonry_box .image_box .video-timer {
	position: absolute;
	top: 15px;
	display: flex;
	align-items: center;
	left: 22px;
	z-index: 7;
}
.masonry_box .image_box .video-timer .icon svg {
	background: #004c66;
	width: 35px;
	height: 35px;
	padding: 7px 8px;
	border-radius: 50%;
	border: 5px solid #ffffffb0;
	cursor: pointer;
}
.masonry_box .set_images {
	z-index: 2;
	position: relative;
}

.masonry_box .set_images .video-timer {
	position: absolute;
	top: 17px;
	display: flex;
	align-items: center;
	right: 22px;
	z-index: 99999;
}
.masonry_box .set_images .video-timer .icon svg {
	background: #004c66;
	width: 40px;
	height: 40px;
	padding: 7px 8px;
	border-radius: 50%;
	border: 5px solid #ffffffb0;
	cursor: pointer;
}
.masonry_box .image_box .video-timer .time {
	margin: 0;
	font-size: 16px;
	font-family: "Vietnam-reg";
	padding-left: 15px;
}
.masonry_box .image_box figure img {
	border-radius: 8px;
}
.masonry_box .image_box .bottom-bar {
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	margin: 0px auto;
	display: table;
	transform: translateY(200%);
	transition: 0.6s;
	z-index: 3;
}
.masonry_box .image_box .bottom-bar button {
	border: 0;
	background: transparent;
	box-shadow: none;
	outline: none;
	padding: 0 10px;
	margin: 0 5px;
}
.bottom-bar svg.bottom_icons {
	width: 20px;
	height: 20px;
	object-fit: cover;
}
.masonry_box .image_box figure {
	margin: 0;
}
.masonry_box .image_box:hover .bottom-bar {
	transform: translateY(0);
}
.image_sec .trending_images {
	padding-top: 50px;
}
.image_sec .trending_images .heading_wrapper {
	padding-bottom: 15px;
	border-bottom: 1px solid #ffffff;
	position: relative;
}
.image_sec .trending_images .heading_wrapper:before {
	content: "";
	background: #fff;
	width: 20%;
	height: 3px;
	position: absolute;
	left: 0;
	bottom: -2px;
}
@media (max-width: 1280) {
	.image_sec .flter-box {
		width: 35%;
	}
}
@media (max-width: 1100px) {
	.image_sec .topbar {
		display: block;
		text-align: center;
	}
	.image_sec .topbar .filter-div {
		justify-content: center;
	}
	.image_sec .topbar .right-bar {
		text-align: center;
		margin-top: 25px;
	}
	.image_sec .flter-box {
		text-align: left;
		position: absolute;
		right: 0;
		left: 0;
		margin: 0px auto;
		width: 50%;
	}
}
@media (max-width: 991px) {
	.image_sec .flter-box {
		top: 105%;
	}
}
@media (max-width: 768px) {
	.image_sec .flter-box .radio-box .head {
		padding: 0 0px;
	}
	.image_sec .flter-box .radio-box.tags ul {
		padding: 0 0 0 0px;
	}
	.image_sec {
		background-size: 100% 5%;
	}
}
@media (max-width: 768px) {
	/* .image_sec .flter-box {
		top: 58%;
	} */
	.image_sec .topbar .filter-div ul.filters li {
		padding: 12px 20px;
		margin-right: 10px;
	}
}
@media (max-width: 575px) {
	.image_sec .flter-box {
		/* top: 81%; */
		width: 100%;
	}
	.image_sec .flter-box .topbar {
		margin-top: 18px;
	}
	.image_sec .flter-box .cancel {
		right: 8px;
		top: 4px;
	}
	.image_sec .topbar .filter-div {
		display: block;
	}
	.image_sec .topbar .filter-div ul.filters li {
		display: block;
		width: 50%;
		margin: 0px auto 5px;
	}
	.image_sec .topbar .filter-div .button-group {
		margin-top: 10px;
	}
	.masonry_box {
		padding-top: 30px;
	}
	.image_sec .right-bar .button-group .filter {
		width: 100%;
		margin-top: 10px;
	}
	.image_sec {
		padding-bottom: 70px;
	}
}
@media (max-width: 480px) {
	/* .image_sec .flter-box {
		top: 55%;
	} */
	.image_sec {
		background-size: 100% 2%;
	}
}
@media (max-width: 320px) {
	.image_sec .topbar .filter-div ul.filters li {
		width: 70%;
	}
}
/* Image Sec Css End Here */
