.profile .download-history-wrapper {
	padding: 50px 50px;
	border: 1px solid #828496;
	border-radius: 7px;
	position: relative;
	overflow: hidden;
}
.setImage43 {
	background-color: #05092c;
	color: #fff;
}
.set_btnsd {
	color: #fff !important;
	font-size: 19px !important;
	padding: 10px 16px;
}
.set_btnsd:hover {
	background-color: #00bbff !important;
	color: #05092c !important;
}
.profile .download-history-wrapper .flter-box {
	z-index: 9;
	width: 46.5%;
	left: 48.3%;
	top: 6%;
	height: 700px;
	overflow: scroll;
}
.profile .download-history-wrapper .flter-box .topbar {
	display: flex;
	align-items: center;
	padding: 15px 0;
	position: sticky;
	top: 0px;
	background: #004d64;
	z-index: 9;
}
.profile .download-history-wrapper .flter-box .cancel {
	position: absolute;
	right: 15px;
	background: transparent;
	border: 0;
	top: 15px;
	padding: 0;
	z-index: 10;
}
.profile .download-history-wrapper .flter-box .radio-box .head {
	padding: 0 25px;
}
.profile .download-history-wrapper .download-heading-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.profile .download-history-wrapper .download-heading-wrapper h3 {
	flex: 0 0 40%;
}
.profile .download-history-wrapper .download-heading-wrapper .asset-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex: 0 0 54%;
	justify-content: end;
}
.profile
	.download-history-wrapper
	.download-heading-wrapper
	.asset-wrapper
	.asset-list-wrapper {
	display: flex;
	align-items: center;
	flex: 0 0 60%;
}
.bg-data {
	background-color: #05092c !important;
}
.profile
	.download-history-wrapper
	.download-heading-wrapper
	.asset-wrapper
	.filter-wrapper {
	flex: 0 0 40%;
	text-align: end;
}
.profile
	.download-history-wrapper
	.download-heading-wrapper
	.asset-wrapper
	.asset-list-wrapper
	h6 {
	font-size: 15px;
	flex: 0 0 40%;
	font-family: var(--font-medium);
	font-weight: 500;
	margin: 0px;
}
.profile
	.download-history-wrapper
	.download-heading-wrapper
	.asset-wrapper
	.filter-wrapper
	button.btn {
	background: var(--extra-color-3);
	padding: 10px 36px;
	height: 53px;
	margin-top: 0px;
}
.profile
	.download-history-wrapper
	.download-heading-wrapper
	.asset-wrapper
	.filter-wrapper
	button.btn
	span {
	margin-left: 10px;
}
.profile .download-content-wrapper {
	margin-top: 40px;
	display: flex;
	flex-wrap: wrap;
	max-height: 700px;
	overflow-y: scroll;
}
.profile .download-content-wrapper::-webkit-scrollbar {
	background-color: var(--default-text-color);
	width: 3px;
}
.profile .download-content-wrapper::-webkit-scrollbar-thumb {
	background-color: var(--extra-color-2);
}
.profile
	.download-history-wrapper
	.download-heading-wrapper
	.asset-wrapper
	.asset-list-wrapper
	select {
	background-color: transparent;
	background-image: url("../img/down-arrow.png");
	background-position: 95% center;
	background-repeat: no-repeat;
	border: 1px solid #828496;
	height: 50px;
	border-radius: 9px;
	color: #fff;
}
.profile .download-history-wrapper .download-img-wrapper {
	position: relative;
	flex: 0 0 33%;
	padding: 0px 7px;
	padding-bottom: 20px;
	overflow: hidden;
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btn-wtapper
	ul.dropdown-menu {
	background: var(--extra-color-1);
	border: 2px solid var(--default-text-color);
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btn-wtapper
	ul.dropdown-menu
	li
	a.dropdown-item {
	color: var(--default-text-color);
	font-family: var(--font-default);
	padding: 10px 16px;
	border-bottom: 1px solid #50536c;
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btn-wtapper
	ul.dropdown-menu
	li:last-child
	a.dropdown-item {
	border-bottom: 0px;
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btn-wtapper
	ul.dropdown-menu
	li
	a.dropdown-item:hover {
	background: var(--extra-color-2);
	color: var(--extra-color-1);
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btn-wtapper
	ul.dropdown-menu
	li
	button.dropdown-item {
	color: var(--default-text-color);
	font-family: var(--font-default);
	padding: 10px 16px;
	border-bottom: 1px solid #50536c;
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btn-wtapper
	ul.dropdown-menu
	li:last-child
	button.dropdown-item {
	border-bottom: 0px;
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btn-wtapper
	ul.dropdown-menu
	li
	button.dropdown-item:hover {
	background: var(--extra-color-2);
	color: var(--extra-color-1);
}
.profile .download-history-wrapper .download-img-wrapper img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-radius: 8px;
}
.profile .download-history-wrapper .download-img-wrapper video {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-radius: 8px;
}
.profile .download-history-wrapper .download-img-wrapper .dropdown {
	position: absolute;
	right: 8px;
	top: 4px;
}

.profile .download-history-wrapper .download-img-wrapper .dropdown button {
	background: transparent;
	border: 0px;
	font-size: 29px;
	color: var(--extra-color-2);
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btns-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 2%;
	padding: 20px 0px 5px 0px;
	left: 0;
	right: 0;
	margin: 0px 7px;
	background-image: linear-gradient(
		to bottom,
		#05193700,
		#04163400,
		#202342e1,
		#202342ee,
		#202342
	);
	transform: translateY(35px);
	border-radius: 0px 0px 6px 6px;
	opacity: 0;
}
.profile
	.download-history-wrapper
	.download-img-wrapper:hover
	.download-btns-wrapper {
	transition: all 0.4s ease-in-out;
	transform: translateY(0px);
	opacity: 1;
}
.profile
	.download-history-wrapper
	.download-img-wrapper
	.download-btns-wrapper
	button {
	background: transparent;
	border: 0px;
	color: var(--default-text-color);
	margin: 0px 18px 0px 0px;
}
@media (max-width: 1280px) {
	.profile .download-history-wrapper .download-heading-wrapper h3 {
		font-size: 30px;
	}
	.profile .download-history-wrapper .flter-box {
		top: 5%;
	}
}

@media(max-width:1200px){
	.profile
	.download-history-wrapper
	.download-heading-wrapper
	.asset-wrapper
	.asset-list-wrapper
	h6 {
    font-size: 12px;
}

.profileSidebar .profile-menu-links-wrapper ul li a span {
    font-size: 13px;
}

.profile .my-account-wrapper .form-group label {
    font-size: 15px;
}

.profile .my-account-wrapper .pass-btn-field-wrapper button.edit-pass {
    font-size: 10px;
}

.profile .my-account-wrapper .form-group input {
    font-size: 14px;
}
}

@media (max-width: 1024px) {
	.profile .download-history-wrapper .download-img-wrapper {
		flex: 0 0 50%;
	}
	.profile
		.download-history-wrapper
		.download-heading-wrapper
		.asset-wrapper
		.asset-list-wrapper
		h6 {
		font-size: 12px;
	}
	.profile .download-history-wrapper .download-heading-wrapper .asset-wrapper {
		flex: 0 0 67%;
	}
	.profile .download-history-wrapper .download-heading-wrapper h3 {
		font-size: 23px;
		flex: 0 0 33%;
	}
	.profile
		.download-history-wrapper
		.download-heading-wrapper
		.asset-wrapper
		.asset-list-wrapper {
		flex: 0 0 56%;
	}
	.profile
		.download-history-wrapper
		.download-heading-wrapper
		.asset-wrapper
		.filter-wrapper
		button.btn {
		padding: 8px 30px;
	}
}

@media(max-width:991px){
	.profile .download-history-wrapper {
		margin-top: 40px;
	}

	.profile .my-account-wrapper .form-group label {
		margin-bottom: 5px;
	}

	.profile .my-account-wrapper .form-group input {
		margin-bottom: 20px;
	}

	.profile .my-account-wrapper .form-group {
		margin-bottom: 0rem;
	}
	
	.profile .my-account-wrapper .pass-btn-field-wrapper button.edit-pass {
		margin-top: -25px;
	}

	.profile .my-account-wrapper .form-group .col-lg-4 {
		 margin-top: 0px; 
	}

	.profile .my-account-wrapper .form-group .col-lg-4:first-child {
		margin-bottom: 0px; 
	}
}

@media(max-width:768px){
	.profile .download-history-wrapper .download-heading-wrapper h3 {
		font-size: 21px;
	}

	.profile .download-history-wrapper .download-heading-wrapper .asset-wrapper .asset-list-wrapper h6 {
		font-size: 10px;
	}

	.profile .download-history-wrapper .download-heading-wrapper {
		flex-direction: column;
		align-items: flex-start;
	}

	.profile
		.download-history-wrapper
		.download-heading-wrapper
		.asset-wrapper
		.asset-list-wrapper {
		margin-top: 30px;
		flex: 0 0 100%;
	}

	.profile .download-history-wrapper .download-heading-wrapper .asset-wrapper {
		flex: 0 0 100%;
		width: 100%;
	}
}

@media (max-width: 575px) {
	.profile .download-history-wrapper .flter-box .radio-box .head {
		padding: 0 5px;
	}
	.profile .download-history-wrapper {
		padding: 30px 20px;
	}
	.profile .download-history-wrapper .download-img-wrapper {
		flex: 0 0 100%;
	}

	.profile
		.download-history-wrapper
		.download-heading-wrapper
		.asset-wrapper
		.filter-wrapper {
		flex: 0 0 100%;
		margin: 13px 0px;
	}
	.profile
		.download-history-wrapper
		.download-heading-wrapper
		.asset-wrapper
		.filter-wrapper
		button.btn {
		width: 100%;
	}
	.profile .download-history-wrapper .flter-box {
		top: 15%;
		width: 100%;
		left: 0%;
	}
}
@media (max-width: 425px) {
	/* .profile .download-history-wrapper .flter-box {
		top: 15%;
		width: 100%;
		left: 0%;
	} */
}
