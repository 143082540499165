.Artist .pyment-extra-heading {
	padding-bottom: 47px;
}
.payment .my-account-heading-wrapper {
	padding-bottom: 24px !important;
}
.Artist .my-account-wrapper .form-group input.edit {
	width: 100% !important;
}
.Artist .my-account-wrapper .form-group input[type="password"] {
	width: 100% !important;
}
/* responsive starts here */

@media(max-width:991px){
	.Artist .pyment-extra-heading {
		padding-bottom: 10px;
	}
}

@media (max-width: 768px) {
	.Artist .pyment-extra-heading {
		padding-bottom: 0px;
	}
}
/* responsive ends here */
