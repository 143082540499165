.register-sec {
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
	padding: 193px 0px 300px 0px;
}
.register-sec .register-card-wrapper {
	padding: 60px 55px;
	border: 1px solid #ffffff70;
	border-radius: 6px;
	background: #040b2ba3;
	
}

.register-sec .register-card-wrapper .register-heading-wrapper {
	text-align: center;
}
.table_content_list  li{
	list-style: none;
	text-decoration: none;
	font-family: var(--font-light);
	font-size: 15px;
}
.register-sec .register-card-wrapper .register-form-wrapper .form-group {
	margin-bottom: 14px;
}
.register-sec .register-card-wrapper .register-form-wrapper .form-group span {
	font-family: var(--font-light);
	font-size: 14px;
}
.register-sec .register-heading-wrapper p.get {
	color: var(--extra-color-2);
	font-size: 20px;
	font-family: var(--font-light);
}
.register-sec .register-card-wrapper .register-form-wrapper .form-group label {
	margin-bottom: 10px;
	font-family: var(--font-semibold);
}
.register-sec
	.register-card-wrapper
	.register-form-wrapper
	.form-group
	.pass-label-wrapper {
	display: flex;
	justify-content: space-between;
}
.register-sec
	.register-card-wrapper
	.register-form-wrapper
	.form-group
	label
	span {
	color: var(--extra-color-2);
}
.register-sec
	.register-card-wrapper
	.register-form-wrapper
	.form-group
	.pass-label-wrapper
	a {
	color: var(--extra-color-2);
}
.register-sec .register-card-wrapper .register-form-wrapper input.form-control {
	height: 47px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	margin-bottom: 14px;
}
.register-sec .register-card-wrapper button.btn.form-control {
	height: 53px;
}
.register-sec .register-card-wrapper button.btn.form-control:hover {
	background: var(--extra-color-2);
}
.register-sec .register-card-wrapper .login-heading-wrapper a {
	color: var(--extra-color-2);
}
.register-sec .register-card-wrapper .register-heading-wrapper p {
	font-size: 16px;
	font-family: var(--font-light);
}

.register-sec .register-card-wrapper .register-heading-wrapper p a {
	color: var(--extra-color-2);
}
/* responsive starts here */
@media (max-width: 1024px) {
	.register-sec {
		background-size: 100% 13%;
	}
}
@media (max-width: 480px) {
	.register-sec .register-card-wrapper {
		padding: 36px 20px;
	}
	.register-sec
		.register-card-wrapper
		.register-form-wrapper
		.form-group
		.pass-label-wrapper {
		flex-direction: column-reverse;
	}
	.register-sec
		.register-card-wrapper
		.register-form-wrapper
		.form-group
		span.account {
		margin-bottom: 14px;
	}
}
/* responsiove ends here */

.modal_body{
	position: relative;
	z-index: 2;
}