.login-modal {
	padding: 100px 0px 300px 0px;
	background-color: #05092ce6;
}
.login-modal .modal-content {
	background: transparent;
}

.img-box figure img {
	height: 102px !important;
	object-fit: cover;
}

.address-box .dot {
	width: 14px;
	height: 14px;
	position: absolute;
	right: 15px;
	border-radius: 50%;
	border: 1px solid #d9d9d9;
	background: transparent;
}
.addresses .address-container .span-checkmark .address-box {
	border: 1px solid #131313;
}
.addresses .address-container .span-checkmark .address-box {
	border: 1px solid #fff;
}
.addresses .address-container .span-checkmark .address-box p {
	line-break: anywhere;
}
.addresses .address-container input:checked ~ .span-checkmark .address-box {
	border-color: #fff;
}
.addresses
	.address-container
	input:checked
	~ .span-checkmark
	.address-box
	.dot {
	background: #f0d453;
}
.ButtonPreview .Paypal_btn {
	width: 100%;
	padding: 12px;
	border: none;
	background: #153a83;
	color: white;
	font-size: 19px;
	font-weight: bold;
	border-radius: 6px;
}
.ButtonPreviewsd .stripe_btn {
	width: 100%;
	padding: 12px;
	border: none;
	background: #6772e6;
	color: #fff;
	font-size: 19px;
	font-weight: bold;
	border-radius: 6px;
	margin-bottom: 20px;
}
.ButtonPreview .Paypal_btn .paypal_pal {
	color: #26a1e0;
}

.login-modal .modal-content .modal-body {
	padding: 0px;
}

.login-modal .modal-dialog {
	max-width: 600px;
}
.login-modal .login-card-wrapper {
	padding: 60px 55px;
	border: 1px solid #ffffff70;
	border-radius: 6px;
	position: relative;
}
.login-modal .login-card-wrapper .login-heading-wrapper p br {
	display: none;
}
.login-modal .form-control {
	background: #141737;
	border-radius: 4px;
	color: var(--extra-color-4);
	font-family: var(--font-light);
	font-size: 12px;
	height: 47px;
	margin-bottom: 20px;
	resize: none;
	appearance: auto !important;
}
.login-modal .login-card-wrapper .login-heading-wrapper {
	text-align: center;
}
.login-modal .login-card-wrapper .login-form-wrapper .form-group {
	margin-bottom: 15px;
}
.login-modal .login-card-wrapper .login-form-wrapper .form-group label {
	margin-bottom: 10px;
	font-family: var(--font-semibold);
}
.login-modal
	.login-card-wrapper
	.login-form-wrapper
	.form-group
	.pass-label-wrapper {
	display: flex;
	justify-content: space-between;
}
.login-modal .login-card-wrapper .login-form-wrapper .form-group label span {
	color: var(--extra-color-2);
}
.login-modal
	.login-card-wrapper
	.login-form-wrapper
	.form-group
	.pass-label-wrapper
	a {
	color: var(--extra-color-2);
}
.login-modal .login-card-wrapper .login-form-wrapper input.form-control {
	height: 47px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	margin-bottom: 20px;
}
.login-modal .login-card-wrapper button.btn.form-control {
	height: 53px;
}
.login-modal .login-card-wrapper button.btn.form-control:hover {
	background: var(--extra-color-2);
}
.login-modal .login-card-wrapper .login-heading-wrapper a {
	color: var(--extra-color-2);
}
.login-modal button.modal-close {
	position: absolute;
	right: 4%;
	top: 3%;
	background: transparent;
	border: 0px;
	font-family: "FontAwesome";
	color: var(--default-font-color);
	font-weight: 800;
	font-size: 12px;
}
/* Checkout Css Start Here */
.checkout_sec {
	padding-top: 128px;
	padding-bottom: 150px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}
.checkout_sec .checkout-form {
	max-width: 80%;
}
.checkout_wrapper {
	border: 1px solid #ffffff4d;
	border-radius: 8px;
	padding: 30px 60px 80px;
}
.checkout_wrapper .topbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 40px;
}
.checkout_wrapper .topbar p {
	margin: 0;
}
.checkout_wrapper .topbar h4 {
	text-transform: uppercase;
	font-size: 22px;
}
.checkout_wrapper .topbar p a {
	font-family: var(--font-semibold);
}
.checkout_wrapper .form-group {
	margin-bottom: 20px;
}
.checkout_wrapper .form-group label {
	font-family: "Vietnam-reg";
	margin-bottom: 5px;
}
.checkout_wrapper .form-group .form-control {
	border: 1px solid #ffffff70;
	box-shadow: none;
	outline: none;
	background: #141737;
	border-radius: 4px;
	padding: 12px 15px;
	font-size: 12px;
	font-family: "Vietnam-reg";
	color: #bbbbbb;
}
.checkout_wrapper .form-group .form-control::placeholder {
	color: #bbbbbb;
}
.checkout_wrapper .payment_method {
	padding-top: 20px;
}
.checkout_wrapper .payment_method .heading h3 {
	font-size: 20px;
	line-height: 30px;
}
.checkout_wrapper .payment_method .icon {
	display: flex;
	align-items: center;
	padding-top: 10px;
}
.checkout_wrapper .payment_method .icon img {
	margin-right: 20px;
}
.checkout_wrapper .detail-box {
	border: 1px solid #ffffff4d;
	border-radius: 8px;
	padding: 15px 25px 50px;
}
.checkout_wrapper .detail-box .heading h4 {
	font-size: 22px;
	line-height: 32px;
}
.checkout_wrapper .detail-box .product-list {
	height: 370px;
	overflow-y: scroll;
}
.checkout_wrapper .detail-box .product-list::-webkit-scrollbar {
	width: 0.3em;
}
.checkout_wrapper .detail-box .product-list::-webkit-scrollbar-thumb {
	background-color: darkgrey;
}
.checkout_wrapper .detail-box .product-box {
	display: flex;
	border-bottom: 1px solid #c5c5c5;
	padding: 25px 0;
	margin-right: 10px;
}
.checkout_wrapper .detail-box .product-box .img-box {
	min-width: 200px;
}
.checkout_wrapper .detail-box .product-box .img-box img {
	width: 180px;
}
.checkout_wrapper .detail-box .product-box .img-box img {
	border-radius: 10px;
}
.checkout_wrapper .detail-box .product-box h4.name {
	font-size: 18px;
	line-height: 28px;
}
.checkout_wrapper .detail-box .product-box h2 {
	font-size: 25px;
}
.checkout_wrapper .detail-box .product-box p {
	font-size: 16px;
}
.checkout_wrapper .detail-box .product-box .img-box img {
	width: 180px;
}
.checkout_wrapper .detail-box .item-total {
	border-bottom: 1px solid #c5c5c5;
	padding: 30px 0 20px;
}
.checkout_wrapper .detail-box .item-total h5 {
	padding-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 18px;
	text-transform: uppercase;
}
.checkout_wrapper .detail-box .item-total h5 span.value {
	font-family: var(--font-light);
}
.checkout_wrapper .detail-box .order-total {
	padding-top: 20px;
}
.checkout_wrapper .detail-box .order-total h5 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: uppercase;
	font-size: 18px;
	line-height: 28px;
}
.checkout_wrapper .detail-box .order-total h5 span.value {
	font-family: var(--font-light);
}
.checkout_wrapper .bottom-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #ffffff7a;
	padding-top: 40px;
	max-width: 95%;
}

.check_radius {
	font-size: 14px;
	margin-left: 8px;
}
.checkout_wrapper .bottom-bargs {
	border: none !important;
}
.checkout_wrapper .detail-box .product-box .img-box video {
	height: 120px;
	width: 180px;
	object-fit: cover;
	border-radius: 10px;
}
.checkout_wrapper .detail-box .product-box .img-box img {
	height: 120px;
	width: 180px;
}
.checkout_wrapper .bottom-bar .form-group {
	margin: 0;
}
.checkout_wrapper .bottom-bar .form-group label {
	margin-left: 15px;
	font-size: 13px;
}
.bottom-bar .form-group input#privacyCheckbox {
	border-radius: 0;
	box-shadow: none;
}
.checkout_wrapper .bottom-bar .button-group {
    display: flex;
    align-items: center;
    width: 600px;
    justify-content: flex-end;
}
.checkout_wrapper .bottom-bar .button-group button.btn {
	margin: 0;
	padding: 10px 60px;
}
.checkout_wrapper .bottom-bar .button-group .cart-btn {
	margin-right: 30px;
	color: #fff;
	background: transparent;
	border: 0;
	padding: 0;
	font-family: var(--font-light);
}
.checkout_wrapper .bottom-bar .button-group .btn {
	margin: 0px;
}
.checkout_wrapper .bottom-bar .button-group .cart-btn i {
	margin-right: 10px;
}
.checkout_wrapper .detail-box .order-total span.property {
	color: var(--extra-color-2);
}
.checkout_modal .login-card-wrapper {
	padding: 30px 20px;
}

.checkout_modal .login-card-wrapper .sub-pack-wrapper h3 {
	font-size: 22px;
	line-height: 25px;
}

.checkout_modal .login-card-wrapper .sub-pack-wrapper {
	border: 1px solid #dddddd78;
	padding: 10px 15px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 5px;
}
.radio_main {
	display: flex;
	align-items: center;
	gap: 20px;
}
@media (max-width: 1400px) {
	.checkout_sec .checkout-form {
		max-width: 95%;
	}
	.checkout_wrapper .bottom-bar {
		max-width: 100%;
	}
	.checkout_wrapper .detail-box .product-box .img-box {
		min-width: 115px;
	}
	.checkout_wrapper .detail-box .product-box .img-box img {
		width: 120px;
		height: 120px;
		object-fit: cover;
	}
	.checkout_wrapper .detail-box .product-box .txt {
		padding-left: 15px;
	}
	.checkout_wrapper .detail-box .item-total h5 {
		font-size: 14px;
	}
	.checkout_wrapper .detail-box .order-total h5 {
		font-size: 14px;
	}
	.checkout_wrapper .bottom-bar .form-group {
		flex: 0 0 40%;
		display: flex;
	}
	.bottom-bar .form-group input#privacyCheckbox {
		flex: 0 0 5%;
	}
	.checkout_wrapper .bottom-bar .form-group label {
		font-size: 13px;
		flex: 0 0 95%;
	}
}
@media (max-width: 1200px) {
	.checkout_wrapper {
		padding: 30px 20px 40px;
		margin-top: 30px;
	}
	.checkout_wrapper .detail-box .product-box h4.name {
		font-size: 16px;
		line-height: 24px;
	}
	.checkout_wrapper .detail-box .product-box h2 {
		font-size: 20px;
	}
	.checkout_wrapper .detail-box .product-box p {
		font-size: 13px;
	}
}
@media (max-width: 991px) {
	.checkout_sec .checkout-form {
		max-width: 100%;
	}
	.checkout_wrapper .bottom-bar {
		margin-top: 40px;
		display: block;
	}
	.checkout_wrapper .bottom-bar .button-group {
		margin-top: 20px;
	}
	.checkout_wrapper .bottom-bar {
		margin-top: 0px;
	}
	.checkout_wrapper .bottom-bar .button-group {
		display: flex;
		align-items: center;
		width: 100% !important;
		justify-content: center;
	}
}

@media (max-width: 768px) {
	.checkout_wrapper .bottom-bar .button-group {
		flex-direction: column-reverse;
	}
	.checkout_wrapper .bottom-bar .button-group .cart-btn {
		margin-right: 0px;
		margin-top: 20px;
	}
}

@media (max-width: 575px) {
	.checkout_sec {
		padding-bottom: 70px;
	}
	.checkout_wrapper .topbar {
		display: block;
	}
	.checkout_wrapper .bottom-bar .form-group {
		display: flex;
	}
	.checkout_wrapper .detail-box .product-box .img-box {
		min-width: 80px;
	}
	.checkout_wrapper .detail-box .product-box .img-box img {
		width: 100%;
		height: 90px;
	}
	.checkout_wrapper .detail-box .product-box {
		display: block;
		text-align: center;
	}
	.checkout_wrapper .detail-box .product-box .txt {
		padding: 15px 0 0 0;
	}
	.checkout_wrapper .payment_method .icon figure img {
		max-width: 100px;
	}
	.checkout_wrapper .bottom-bar .button-group button.cart-btn {
		margin-top: 15px;
	}
	.bottom-bar .form-group input#privacyCheckbox {
		width: 20px;
	}
	.checkout_wrapper .detail-box .item-total h5 {
		font-size: 11px;
	}
	.login-modal .login-card-wrapper {
		padding: 60px 15px;
	}
	.login-modal .form-control {
		font-size: 10px;
	}
	.address-box .address-title {
		font-size: 23px;
	}
}
@media (max-width: 425px) {
	.checkout_wrapper .bottom-bar .button-group .btn {
		margin: 0px;
		width: 100%;
		margin-bottom: 17px;
	}
	.checkout_wrapper .detail-box {
		padding: 15px 7px 50px;
	}
}
/* Checkout Css End Here */
