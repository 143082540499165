.Artist {
	padding: 50px 0px;
}
.artistHeader {
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-position: top center;
	background-repeat: no-repeat;
	padding: 142px 0px;
}
.artistHeader .artist-avatar-wrapper {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.artistSidebar .artist-menu-links-wrapper ul li a.active span {
	color: #00bbff;
}
.artistHeader .artist-avatar-wrapper .artist-img-wrapper {
	position: relative;
	/* overflow: hidden; */
	height: 145px;
	width: 145px;
	border-radius: 50%;
}
.artistHeader .artist-avatar-wrapper .artist-img-wrapper .changeimg {
	position: absolute;
	bottom: -23%;
	right: 0px;
	left: 0;
	background: #f2f2f240;
	border: 0px;
	font-family: var(--font-default);
	cursor: pointer;
	font-size: 9px;
	padding: 7px 0px;
	color: #00bbff;
	text-decoration: underline;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
}
.artistHeader .artist-avatar-wrapper img {
	height: 145px;
	width: 145px;
	border-radius: 50%;
	outline: 32px solid #05092c;
	margin: 0px auto;
	object-fit: cover;
	object-position: center;
}
.artistSidebar .artist-menu-links-wrapper ul {
	padding: 0px;
	list-style: none;
	margin: 0px;
}
.artistSidebar .artist-menu-links-wrapper {
	border: 1px solid #828496;
	border-radius: 10px;
	padding: 10px 0px;
}
.artistSidebar .artist-menu-links-wrapper ul li {
	border-bottom: 1px solid #828496;
	padding: 19px 20px;
}
.artistSidebar .artist-menu-links-wrapper ul li:last-child {
	border-bottom: 0px;
}
.artistSidebar .artist-menu-links-wrapper ul li a img {
	margin-right: 10px;
	width: 21px;
	object-fit: contain;
	height: 21px;
}
.artistSidebar .artist-menu-links-wrapper ul li a span {
	font-family: var(--font-semibold);
	font-size: 15px;
}
.Artist .artist-logout-btn-wrapper {
	margin-top: 30px;
}
.Artist .artist-logout-btn-wrapper a.btn {
	width: 100%;
	font-family: var(--font-default);
}
.Artist .artist-logout-btn-wrapper button.btn {
	width: 100%;
	font-family: var(--font-default);
}
.artistHeader .artist-avatar-wrapper .artist-content-wrapper {
	text-align: left;
	margin-left: 36px;
}

.artistHeader .artist-avatar-wrapper .artist-content-wrapper h4 {
	font-size: 30px;
}

.artistHeader .artist-avatar-wrapper .artist-content-wrapper h6 {
	font-size: 14px;
	font-family: var(--font-default);
	font-weight: 400;
}

.artistHeader .artist-avatar-wrapper .artist-content-wrapper h6 span {
	color: var(--extra-color-2);
}
.artistHeader .artist-avatar-wrapper .artist-content-wrapper button.btn {
	padding: 10px 46px;
	font-size: 14px;
}
/* responsive starts here */
@media (max-width: 1024px) {
	.artistSidebar .artist-menu-links-wrapper ul li {
		padding: 19px 9px;
	}
	.artistSidebar .artist-menu-links-wrapper ul li a span {
		font-size: 13px;
	}
	.Artist .my-account-wrapper .form-group label {
		display: block;
		margin-bottom: 1rem;
		font-family: var(--font-semibold);
		font-size: 15px;
	}
}
@media (max-width: 768px) {
	.artistSidebar .artist-menu-links-wrapper ul li {
		padding: 19px 20px;
	}
	.artistSidebar .artist-logout-btn-wrapper a.btn {
		width: 100%;
		margin-bottom: 30px;
	}
	.artistSidebar .artist-logout-btn-wrapper button.btn {
		width: 100%;
		margin-bottom: 30px;
	}
}

@media (max-width: 575px) {
	.artistHeader .artist-avatar-wrapper .artist-content-wrapper {
		text-align: center;
		margin-top: 10px;
		margin-left: 0;
	}

	.artistHeader {
		padding: 142px 0px 20px;
	}

	.artistHeader .artist-avatar-wrapper {
		flex-direction: column;
	}
	.artist-heading-wrapper {
		text-align: center;
	}
	.artistHeader .artist-avatar-wrapper .artist-content-wrapper h4 {
		font-size: 24px;
	}
}

/* responsive ends here */
