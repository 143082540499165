/* Contributor css starts hete */
.what-contributors {
	padding-bottom: 55px;
}
.what-contributors .cont-img-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.what-contributors .cont-img-wrapper figure {
	flex: 0 0 47%;
}
.what-contributors .cont-img-wrapper img.img-fluid {
	width: 308px;
}
.what-contributors .cont-img-wrapper img.img-fluid:nth-child(3) {
	margin-left: 20px;
	width: 281px;
}
.what-contributors .cont-img-wrapper img.img-fluid:nth-child(4) {
	width: 281px;
}
.contributors-content-wrapper h2 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.what-contributors .con-icons-wrapper ul {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	list-style: none;
	align-items: center;
	padding: 0px;
	margin: 0px;
}
.what-contributors .con-icons-wrapper ul li {
	flex: 0 0 50%;
	margin-bottom: 30px;
}

.what-contributors .con-icons-wrapper ul li .icons-wrapper {
	display: flex;
	align-items: center;
}

.what-contributors .con-icons-wrapper ul li .icons-wrapper .icon img.img-fluid {
	max-width: 52px;
	height: 40px;
	object-fit: cover;
}

.what-contributors
	.con-icons-wrapper
	ul
	li
	.icons-wrapper
	.icon-content-wrapper
	h5 {
	font-size: 21px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.what-contributors
	.con-icons-wrapper
	ul
	li
	.icons-wrapper
	.icon-content-wrapper {
	margin-left: 15px;
}

.what-contributors
	.con-icons-wrapper
	ul
	li
	.icons-wrapper
	.icon-content-wrapper
	p {
	font-size: 12px;
	margin-right: 18px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin-bottom: 0px;
}
/* Border Line Hover Css Start Here */
.what-contributors .cont-img-wrapper figure:before {
	content: "";
	background: transparent;
	width: 100%;
	height: 100%;
	position: absolute;
	left: -8px;
	z-index: -1;
	top: 8px;
	border: 1px solid #fff;
	transition: 0.6s;
}
.what-contributors .cont-img-wrapper figure {
	position: relative;
	margin-bottom: 44px;
}
.what-contributors .cont-img-wrapper figure:hover:before {
	left: 8px;
	top: 11px;
}
/* Border Line Hover Css End Here */
/* responsive starts here */
@media (max-width: 1366px) {
	.what-contributors .cont-img-wrapper img.img-fluid {
		width: 266px;
	}
	.what-contributors .cont-img-wrapper img.img-fluid:nth-child(3) {
		margin: 0px;
		width: 266px;
	}
	.what-contributors .cont-img-wrapper img.img-fluid:nth-child(4) {
		margin: 0px;
		width: 266px;
	}
	.what-contributors
		.con-icons-wrapper
		ul
		li
		.icons-wrapper
		.icon-content-wrapper
		p {
		font-size: 12px;
		margin-right: 11px;
	}
}
@media (max-width: 1024px) {
	.what-contributors .cont-img-wrapper img.img-fluid {
		width: 207px;
	}
	.what-contributors .cont-img-wrapper img.img-fluid:nth-child(3) {
		width: 207px;
	}
	.what-contributors .cont-img-wrapper img.img-fluid:nth-child(4) {
		width: 207px;
	}
	.what-contributors
		.con-icons-wrapper
		ul
		li
		.icons-wrapper
		.icon-content-wrapper
		p {
		margin-right: 15px;
	}
	.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper {
		flex: 0 0 10% !important;
	}
}
@media (max-width: 800px) {
	.what-contributors .contributors-content-wrapper {
		margin-top: 50px;
	}
	.why-contribute .why-card-wrapper {
		margin-bottom: 30px;
	}
	.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper {
		flex: 0 0 13% !important;
	}
	.pricing-table-wrapper th h5 {
		font-size: 18px !important;
	}
	.pricing-table-wrapper td.img-heading h6 {
		font-size: 22px !important;
	}
	.pricing-table-wrapper tbody td span {
		font-size: 17px;
	}
}

/* Contributor css ends here */
.what-contributors {
	padding-bottom: 120px;
}
.why-contribute {
	padding-bottom: 100px;
}
.pricing-sec .image_pricing {
	padding-bottom: 100px;
}
.why-contribute .why-card-wrapper {
	padding: 39px 28px;
	border: 1px solid #828496;
	border-radius: 7px;
	height: 100%;
}
.why-contribute .why-card-wrapper .why-img-wrapper {
	background-color: var(--extra-color-2);
	display: flex;
	width: 70px;
	height: 65px;
	justify-content: center;
	align-items: center;
	clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
	margin-bottom: 24px;
}
.why-contribute .why-card-wrapper .why-img-wrapper i {
	font-size: 23px;
	color: var(--extra-color-1);
}
.why-contribute .why-card-wrapper .why-content-wrapper h5 {
	font-size: 25px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.why-contribute .why-card-wrapper .why-content-wrapper p {
	font-size: 14px;
	line-height: 2;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.faq-con {
	background: transparent;
}
.become-con {
	padding: 78px 0px;
}
.become-con .become-heading-wrapper h3 {
	font-size: 32px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.become-con .steps-wrapper ul {
	padding: 0px;
	list-style: none;
}
.become-con .steps-wrapper ul .step-card-wrapper .step-content-wrapper h4 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.become-con .steps-wrapper ul .step-card-wrapper {
	display: flex;
	align-items: center;
	background: #111434;
	padding: 27px 28px;
	margin-bottom: 23px;
}
.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper {
	flex: 0 0 7%;
	background-color: var(--extra-color-2);
	display: flex;
	width: 77px;
	height: 77px;
	justify-content: center;
	align-items: center;
	clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
	margin-bottom: 24px;
}
.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper h3 {
	color: var(--extra-color-1);
	font-size: 50px;
	line-height: 0;
	/* margin-top: 22px; */
	/* margin-left: 10px; */
}
.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper h3 sup {
	font-size: 13px;
	top: -30px;
}
.become-con .steps-wrapper ul .step-card-wrapper .step-content-wrapper {
	flex: 0 0 60%;
	margin-left: 65px;
}
.become-con .steps-wrapper ul .step-card-wrapper .step-content-wrapper p {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.con-vision .mission-content-wrapper h2 {
	font-size: 33px;
}
.pricing-sec {
	padding: 62px 0px;
}

.pricing-sec .pricing-heading-wrapper {
	text-align: center;
	margin-bottom: 54px;
}
.pricing-sec .pricing-heading-wrapper h3 {
	font-size: 40px;
}
.pricing-table-wrapper td.img-heading h6 {
	font-size: 28px;
	margin: 0px;
}
.pricing-table-wrapper th,
.pricing-table-wrapper td {
	border: 0px;
	color: var(--default-text-color);
}
.pricing-table-wrapper th.img-empty-heading {
	width: 20%;
	background: var(--extra-color-1);
	/* border: 1px solid;
	border-radius: 13px; */
}
.pricing-table-wrapper th:nth-child(2) {
	background: var(--extra-color-2);
	text-align: center;
	padding-top: 32px;
	padding-bottom: 18px;
	border-top: 1px solid var(--extra-color-2);
	border-left: 1px solid;
}
.pricing-table-wrapper th:first-child span.empty {
	height: 95px;
	border: 1px solid;
	display: block;
	width: 100%;
	border-radius: 13px 0px 0px 0px;
	border-bottom: 0px;
	border-right: 0;
}
.pricing-table-wrapper th:nth-child(3) {
	background: var(--extra-color-2);
	text-align: center;
	padding-top: 32px;
	padding-bottom: 18px;
	border-top: 1px solid var(--extra-color-2);
}
.pricing-table-wrapper th:nth-child(4) {
	background: var(--extra-color-2);
	text-align: center;
	padding-top: 32px;
	border-radius: 0px 13px 0px 0px;
	padding-bottom: 18px;
	/* border-top: 1px solid var(--extra-color-2); */
}
.pricing-table-wrapper th h5 {
	margin: 0px;
	font-size: 30px;
}
.pricing-table-wrapper tbody td {
	text-align: center;
	vertical-align: middle;
}
.pricing-table-wrapper tbody td span {
	font-size: 20px;
	font-weight: 400;
	padding: 7px 0px;
	display: block;
}
.pricing-table-wrapper tbody td:nth-child(2) {
	background: var(--extra-color-2);
	text-align: center;
	border-left: 1px solid;
}
.pricing-table-wrapper tbody td:nth-child(3) {
	background: var(--extra-color-2);
	text-align: center;
}
.pricing-table-wrapper tbody td:nth-child(4) {
	background: var(--extra-color-2);
	text-align: center;
}
.pricing-table-wrapper tbody tr:last-child td {
	padding-bottom: 31px;
}
.pricing-table-wrapper tbody tr:last-child td:last-child {
	border-radius: 0px 0px 13px 0px;
}
/* .pricing-table-wrapper tbody tr:nth-child(odd) td {
	filter: saturate(0.9);
} */
.pricing-table-wrapper tbody tr:nth-child(odd) td {
	background-color: #00a3de;
}
.pricing-table-wrapper tbody tr td:first-child {
	border-left: 1px solid;
}
.pricing-table-wrapper tbody tr:last-child td:first-child {
	border-bottom: 0px;
	border-left: 0px;
	padding: 0px;
}
.pricing-table-wrapper tbody tr:last-child td:first-child span.empty {
	border: 1px solid;
	border-top: 0px;
	border-right: 0px;
	height: 83px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 0px 0px 13px;
}
.pricing-table-wrapper th:first-child {
	border-bottom: 0px;
	border-right: 0px !important;
	padding: 0px;
}
/* .pricing-table-wrapper tbody tr:nth-child(odd) td:first-child {
	filter: revert;
	background: #111434;
} */
.pricing-table-wrapper tbody tr:nth-child(odd) td:first-child {
	filter: revert;
	background: var(--light-background-color);
}
.pricing-sec .pricing-below-content-wrapper {
	padding-top: 48px;
}
.pricing-sec .pricing-below-content-wrapper p {
	font-size: 15px;
}
.pricing-sec
	.pricing-below-content-wrapper
	.pricing-list-wrapper
	ul
	li::before {
	content: "\f00c   ";
	font-family: "FontAwesome";
	font-size: 12px;
	background: var(--extra-color-2) !important;
	width: 20px;
	height: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
	border-radius: 14px;
	color: var(--extra-color-1);
	font-weight: 600;
}
.pricing-sec .pricing-below-content-wrapper .pricing-list-wrapper ul {
	padding-left: 0px;
	list-style: none;
	margin-bottom: 35px;
	margin-top: 25px;
}
.pricing-sec .pricing-below-content-wrapper .pricing-list-wrapper ul li {
	line-height: 2;
	font-family: var(--font-default);
	font-weight: 400 !important;
	font-size: 14px;
}
.contributor-join .promotion-card-1-wrapper {
	background-image: url("../img/con-join.png");
	background-size: cover;
	background-repeat: no-repeat;
}
.what-contributors .btn-wrapper a.btn,
.what-contributors .btn-wrapper button.btn {
	background: var(--extra-color-3);
	border: 1px solid var(--extra-color-3);
}
.what-contributors .btn-wrapper a.btn:hover,
.what-contributors .btn-wrapper button.btn:hover {
	background: var(--extra-color-2) !important;
	border-color: var(--extra-color-2) !important;
}
.why-contribute .btn-wrapper a.btn,
.why-contribute .btn-wrapper button.btn {
	background: var(--extra-color-3);
	border: 1px solid var(--extra-color-3);
}
.why-contribute .btn-wrapper a.btn:hover,
.why-contribute .btn-wrapper button.btn:hover {
	background: var(--extra-color-2) !important;
	border-color: var(--extra-color-2) !important;
}
.pricing-sec .btn-wrapper a.btn,
.pricing-sec .btn-wrapper button.btn {
	background: var(--extra-color-3);
	border: 1px solid var(--extra-color-3);
}
.pricing-sec .btn-wrapper a.btn:hover,
.pricing-sec .btn-wrapper button.btn:hover {
	background: var(--extra-color-2) !important;
	border-color: var(--extra-color-2) !important;
}

@media (max-width: 1366px) {
	.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper {
		flex: 0 0 8%;
	}
}

@media (max-width: 1200px) {
	.why-contribute .why-card-wrapper {
		height: 430px;
	}

	.pricing-table-wrapper th h5 {
		font-size: 25px;
	}
}

/* Tablet Responsive  */
@media (max-width: 991px) {
	.what-contributors {
		padding-bottom: 30px;
	}

	.what-contributors .contributors-content-wrapper {
		margin-top: 30px;
	}

	.become-con {
		padding: 40px 0px;
	}

	.pricing-sec {
		padding: 30px 0px;
	}

	.our-vision {
		padding: 0px 0px 30px 0px;
	}

	.pricing-sec .pricing-heading-wrapper {
		margin-bottom: 30px;
	}

	.faq {
		padding: 30px 0px;
	}

	.faq .heading-row {
		margin-bottom: 25px;
	}

	.mission-content-wrapper {
		margin-top: 0px;
	}

	.join {
		padding-bottom: 50px;
		padding-top: 30px;
	}

	.about-page-header {
		padding: 137px 0px 20px 0px;
	}

	.what-contributors .cont-img-wrapper figure {
		margin-bottom: 30px;
	}

	.why-contribute .why-card-wrapper {
		margin-bottom: 30px;
	}
}
/* Tablet Responsive  */

@media (max-width: 575px) {
	.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper {
		flex: 0 0 100% !important;
	}
	.become-con .steps-wrapper ul .step-card-wrapper .step-content-wrapper {
		flex: 0 0 100%;
		margin-left: 9px;
	}
	.become-con .become-heading-wrapper h3 {
		font-size: 28px;
		line-height: 1.3;
	}
	.border-line-right figure:before {
		left: 7px;
		top: 10px;
	}
	.pricing-sec .pricing-below-content-wrapper .pricing-list-wrapper ul li {
		line-height: 3;
		font-size: 11px;
	}
	.what-contributors .con-icons-wrapper ul {
		flex-direction: column;
	}

	.what-contributors
		.con-icons-wrapper
		ul
		li
		.icons-wrapper
		.icon-content-wrapper
		p {
		margin-right: 0;
	}

	/* .what-contributors
    .con-icons-wrapper
    ul
    li
    .icons-wrapper
    .icon-content-wrapper
    p {
    margin-right: 53px;
  } */
	.pricing-table-wrapper tbody td span {
		font-size: 13px;
	}
	.pricing-table-wrapper th h5 {
		font-size: 14px !important;
	}
	.pricing-table-wrapper td.img-heading h6 {
		font-size: 16px !important;
	}
	.pricing-table-wrapper th:nth-child(2) {
		padding-top: 14px;
		padding-bottom: 18px;
	}
	.pricing-table-wrapper th:nth-child(3) {
		padding-top: 14px;
		padding-bottom: 18px;
	}
	.pricing-table-wrapper th:nth-child(4) {
		padding-top: 14px;
		padding-bottom: 18px;
	}
	.pricing-table-wrapper th:first-child span.empty {
		height: 69px;
	}
	.contributor-join {
		padding-bottom: 66px;
	}
	.what-contributors .cont-img-wrapper img.img-fluid {
		width: 100%;
		flex: 0 0 100%;
	}
	.what-contributors .cont-img-wrapper img.img-fluid:nth-child(3) {
		margin-bottom: 24px;
	}
	.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper h3 {
		font-size: 54px;
	}
	.become-con .steps-wrapper ul .step-card-wrapper {
		padding: 30px 10px;
		flex-direction: column;
	}
	.become-con
		.steps-wrapper
		ul
		.step-card-wrapper
		.step-card-img-wrapper
		h3
		sup {
		font-size: 13px;
		top: -18px;
	}
	.what-contributors .cont-img-wrapper figure {
		flex: 0 0 100%;
	}
	.become-con .steps-wrapper ul .step-card-wrapper .step-card-img-wrapper h3 {
		font-size: 41px;
		line-height: 52px;
	}

	.become-con {
		padding: 30px 0px;
	}

	.mission-content-wrapper {
		margin-top: 0px;
	}

	.faq {
		padding: 10px 0px;
	}

	.pricing-sec .pricing-heading-wrapper {
		margin: 30px 0px;
	}

	.pricing-sec {
		padding: 0px 0px 30px;
	}

	.our-vision {
		padding: 0px 0px 20px 0px;
	}

	.what-contributors .cont-img-wrapper figure {
		margin-bottom: 34px;
	}

	.what-contributors .contributors-content-wrapper {
		margin-top: 20px;
	}

	.what-contributors {
		padding-bottom: 20px;
	}

	.what-contributors .con-icons-wrapper ul li {
		margin-bottom: 20px;
	}
}
@media (max-width: 425px) {
	.pricing-table-wrapper th:first-child span.empty {
		width: 141px;
	}
	.pricing-table-wrapper th.Contributor-heading h5 {
		width: 126px;
	}
	.pricing-table-wrapper th.non-exclusive-heading h5 {
		width: 134px;
	}
	.pricing-table-wrapper th.exclusive-heading h5 {
		width: 102px;
	}
}
