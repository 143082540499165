.request-removal-wrapper {
	border: 1px solid #828496;
	padding: 50px 50px;
	border-radius: 10px;
}
.request-removal-wrapper .removal-heading-wrapper {
	margin-bottom: 48px;
	text-align: center;
}
.request-removal-wrapper .request-form-wrapper .form-group {
	margin-bottom: 2rem;
}
.request-removal-wrapper .request-form-wrapper .form-group label {
	margin-bottom: 10px;
	font-family: var(--font-semibold);
}
.request-removal-wrapper .request-form-wrapper .form-group label span {
	color: var(--extra-color-2);
}
.request-removal-wrapper .request-form-wrapper .form-group input.form-control {
	height: 47px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
}
.request-removal-wrapper
	.request-form-wrapper
	.form-group
	input.form-control::placeholder {
	color: #c6c6c6;
	font-family: var(--font-default);
}
.request-removal-wrapper .request-form-wrapper .form-group h6 {
	margin-bottom: 37px;
	margin-top: 20px;
}
.request-removal-wrapper .request-form-wrapper .form-group textarea {
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	margin-bottom: 20px;
}
.request-removal-wrapper
	.request-form-wrapper
	.form-group
	textarea::placeholder {
	color: #c6c6c6;
	font-family: var(--font-default);
}
.request-removal-wrapper .request-form-wrapper .form-group .form-check label {
	font-family: var(--font-default);
	font-size: 14px;
	font-weight: 400;
	margin-left: 16px;
}
.request-removal-wrapper .request-form-wrapper .form-group .form-check input {
	background-color: var(--extra-color-1);
	border: 1px solid var(--extra-color-2);
	border-radius: 0px;
	height: 20px;
	width: 20px;
}
.request-removal-wrapper
	.request-form-wrapper
	.form-group
	.form-check.read
	input {
	border: 1px solid #98a1bc;
}
.request-removal-wrapper
	.request-form-wrapper
	.form-group
	.form-check.read
	label {
	font-size: 13px;
	font-weight: 400;
	margin-left: 2px;
}
.request-removal-wrapper .removal-heading-wrapper h3 {
	font-size: 31px;
}
.request-removal-wrapper
	.request-form-wrapper
	.form-group
	.form-check-input:checked[type="checkbox"] {
	background-image: url("../img/check-icon.png");
	background-size: 76% 51%;
	background-repeat: no-repeat;
}
/* .request-removal-wrapper
	.request-form-wrapper
	.form-group
	.form-check-input[type="radio"] {
	background-image: url("../img/check-icon.png");
	background-size: 76% 51%;
	background-repeat: no-repeat;
} */
.request-removal-wrapper .request-form-wrapper .form-group span.will {
	margin-bottom: 2rem;
	display: block;
	font-weight: 300;
}
.request-removal-wrapper .request-form-wrapper .form-group span.light {
	color: var(--default-text-color);
	margin-bottom: 2rem;
	display: block;
	font-weight: 300 !important;
}
.request-removal-wrapper .request-form-wrapper .form-group .divider-wrapper {
	border-bottom: 1px solid var(--default-text-color);
}
.request-removal-wrapper
	.request-form-wrapper
	.form-group
	.divider-wrapper
	.divider {
	width: 30%;
	border: 1px solid var(--default-text-color);
}
.request-removal-wrapper .request-form-wrapper .form-group .btn-wrapper {
	text-align: end;
}
.request-removal-wrapper
	.request-form-wrapper
	.form-group
	.btn-wrapper
	button.btn {
	/* padding: 13px 33px; */
	font-size: 17px;
}
.request-removal-wrapper
	.request-form-wrapper
	.form-group
	.btn-wrapper
	button.btn.remove2 {
	font-size: 13px;
}
.remove-content .contact-form-modal-content-wrapper h2 {
	font-size: 30px;
}
.remove-content .contact-form-modal-content-wrapper p {
	font-size: 12px !important;
	color: var(--text-color) !important;
}
.remove-content .contact-form-modal-content-wrapper .btn {
	padding: 10px 55px;
}
.remove-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.remove-content .contact-form-modal-content-wrapper img.remove-content {
	margin: 0px auto;
	position: relative;
	margin-top: -90px;
	top: -7px;
	padding-bottom: 30px;
}
.contactmodal .modal-dialog .modal-content .contact-form-modal-content-wrapper {
	text-align: center;
	padding-bottom: 30px !important;
	padding: 0px 40px;
}
.remove-content {
	background: #141737e8;
	backdrop-filter: blur(5px);
}
/* responsive starts here */
@media (max-width: 768px) {
	.request-removal-wrapper
		.request-form-wrapper
		.form-group.row
		.col-lg-6:first-child {
		margin-bottom: 30px;
	}
	.request-removal-wrapper .removal-heading-wrapper h3 {
		font-size: 25px;
	}
}
@media (max-width: 480px) {
	.contactmodal
		.modal-dialog
		.modal-content
		.contact-form-modal-content-wrapper {
		padding-bottom: 30px !important;
		padding: 0 15px;
		text-align: center;
	}
	.request-removal-wrapper {
		padding: 37px 15px;
	}
	.remove-content .contact-form-modal-content-wrapper h2 {
		font-size: 18px;
	}
}
@media (max-width: 320px) {
	.contactmodal
		.modal-dialog
		.modal-content
		.contact-form-modal-content-wrapper {
		padding: 0 3px;
	}
}
/* responsive ends here */
