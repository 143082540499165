.artist-page-header {
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
}
.artist-page-header .heading-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 137px;
}
.artist-page-header .heading-wrapper p a {
	color: var(--extra-color-2);
}
.artist-page-links-wrapper ul.artist-list-wapper {
	padding: 0px;
	list-style: none;
	display: flex;
	cursor: pointer;
}
.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a {
	border: 1px solid #828496;
	padding: 13px 27px;
	position: relative;
	overflow: hidden;
}
/* .artist-page-links-wrapper ul.artist-list-wapper li.artist-item a::before {
  content: attr(title);
  display: block;
  position: absolute; */
/* margin-left: -30px; */
/* top: 0;
  transform-origin: bottom;
  transform-style: preserve-3d;
  perspective: 1000px;
  transform: rotateX(0deg) translateY(0);
  transition: 0.5s;
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 3;
} */
.artist-page-links-wrapper
	ul.artist-list-wapper
	li.artist-item
	a:hover::before {
	transform: rotateX(90deg) translateY(50px);
	background: #031637;
}
.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a::after {
	content: attr(title);
	display: block;
	position: absolute;
	/* margin-left: -30px; */
	top: 0;
	color: #fff;
	background-color: #00bbff;
	transform-origin: top;
	transform-style: preserve-3d;
	perspective: 1000px;
	transform: rotateX(-90deg) translateY(-50%);
	transition: 0.5s;
	width: 100%;
	text-align: center;
	height: 100%;
	line-height: 3;
}
.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a:hover::after {
	transform: rotateX(0deg) translateY(0px);
}
.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a:hover {
	background: var(--extra-color-2);
	transition: 0.4s;
}
.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a.active {
	background-color: var(--extra-color-2);
}
/* .artist-page-links-wrapper ul.artist-list-wapper li.artist-item:first-child a {
  border-radius: 9px 0px 0px 9px;
} */
/* .artist-page-links-wrapper ul.artist-list-wapper li.artist-item:last-child a {
  border-radius: 0px 9px 9px 0px;
} */
.artist-page-links-wrapper {
	margin-top: 31px;
}
.artist-blog {
	background: transparent;
	padding-top: 0px;
}
.paginations-wrapper {
	padding-top: 26px;
}

.paginations-wrapper ul {
	display: flex;
	list-style: none;
	padding: 0px;
}

.paginations-wrapper ul li.pagination-item button {
	border: 1px solid #031637;
	background: #031637;
	height: 51px;
	padding: 10px 19px;
	border-right-color: #818b9b;
	color: var(--default-text-color);
	font-family: "Poppins";
	font-weight: 700;
	font-size: 12px;
}
.paginations-wrapper ul li.pagination-item:last-child button {
	border-radius: 0px 6px 6px 0px;
	border-right-color: transparent;
}
.paginations-wrapper ul li.pagination-item:first-child button {
	border-radius: 6px 0px 0px 6px;
}

.paginations-wrapper ul li.pagination-item button.active {
	background: var(--extra-color-2);
	border-color: transparent;
}
.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a.active:hover:after {
    content: '';
    display:none;
}
.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a.active:hover:before {
    content: '';
    display:none;
}
/* responsive starts here */
@media (max-width: 1400px) {
	.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a {
		border: 1px solid #828496;
		padding: 13px 19px;
	}
}

@media(max-width:1200px){
	.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a {
		padding: 13px 16px;
		font-size: 13px;
	}
}

@media (max-width: 1024px) {
	.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a {
		font-size: 12px;
	}
	.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a::before {
		display: none;
	}
}

@media(max-width:991px){
	.about-img-wrapper.border-line-left {
		margin-bottom: 30px;
	}
	.artist-page-links-wrapper ul.artist-list-wapper {
		flex-wrap: wrap;
		row-gap: 30px;
	}
}

@media (max-width: 768px) {
	.artist-page-links-wrapper ul.artist-list-wapper {
		flex-wrap: wrap;
	}
	.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a {
		line-height: 3.5;
	}
	.artist-page-links-wrapper
		ul.artist-list-wapper
		li.artist-item:first-child
		a {
		border-radius: 0px 0px 0px 0px;
	}
	.artist-page-links-wrapper ul.artist-list-wapper li.artist-item:last-child a {
		border-radius: 0px 0px 0px 0px;
	}

	.terms {
		padding: 140px 0px 20px 0px;
	}

	.recourses-sec {
		padding-top: 30px !important;
	}

}
@media (max-width: 575px) {
	.artist-page-links-wrapper ul.artist-list-wapper li.artist-item a {
		border: 1px solid #828496;
		padding: 0px 19px;
	}
	.artist-page-links-wrapper ul.artist-list-wapper {
		flex-direction: column;
	}
	.artist-page-links-wrapper
		ul.artist-list-wapper
		li.artist-item
		a.artist-link {
		width: 100%;
		display: block;
	}
	.artist-page-links-wrapper
		ul.artist-list-wapper
		li.artist-item:first-child
		a {
		border-radius: 0px 0px 0px 0px;
	}
	.artist-page-links-wrapper ul.artist-list-wapper li.artist-item:last-child a {
		border-radius: 0px 0px 0px 0px;
	}
	.our-mission {
		padding-top: 48px !important;
	}
	.our-mission .about-img-wrapper {
		margin-bottom: 70px;
	}
	.border-line-left figure:before {
		left: -6px;
	}
	.border-line-left:hover .figure:before, .border-line-left:hover figure:before {
		left: 8px;
	}
	.our-vision .about-img-wrapper {
		padding-top: 40px;
	}
	.paginations-wrapper ul {
		justify-content: center;
	}
	.border-line-left figure:before, .border-line-left .figure:before {
		left: -6px;
	}
	.artist-page-header .heading-wrapper {
		align-items: start;
		flex-direction: column;
	}
}
@media (max-width: 320px) {
	.paginations-wrapper ul li.pagination-item button {
		padding: 10px 15px;
	}
}
/* responsive end here */
