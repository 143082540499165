/* Artist Profile Css Start Here */
.artist_profile {
	padding-top: 150px;
	padding-bottom: 60px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}
.artist_profile .top-header {
	display: flex;
	align-items: flex-start;
	max-width: 70%;
}
.artist_profile .top-header .img-box {
	margin-right: 30px;
}
.artist_profile .top-header .img-box img {
	width: 100px;
	border-radius: 50%;
	object-fit: cover;
	height: 100px !important;
}
.artist_profile .top-header .content_wrapper h2 {
	line-height: 40px;
}
.artist_profile .top-header .content_wrapper .uploads {
	font-size: 20px;
	font-family: var(--font-light);
}
.artist_profile .top-header .content_wrapper .uploads span.value {
	padding-left: 15px;
	color: var(--extra-color-2);
}
.artist_profile .masonry_box {
	padding-top: 40px;
}
.artist_profile .filter-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 40px;
}
.artist_profile .filter-bar .button-group .filter {
	background: #004c66;
	border: 0;
	padding: 14px 35px;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	font-family: "Vietnam-reg";
}
.artist_profile .filter-bar .button-group .filter svg {
	margin-right: 15px;
}
.artist_profile .filter-bar .showing-result p {
	margin: 0;
}
.artist_profile .flter-box .cancel {
	position: absolute;
	right: 15px;
	background: transparent;
	border: 0;
	top: 15px;
	padding: 0;
}
.artist_profile .flter-box ul.filters {
	padding: 0 0;
	list-style: none;
	margin: 0;
}
.artist_profile .flter-box ul.filters li {
	display: inline-block;
	background: #004c6661;
	color: #fff;
	font-size: 12px;
	padding: 12px 30px;
	margin-right: 20px;
	border: 1px solid #cecece63;
	position: relative;
}
.artist_profile .flter-box ul.filters li span.cancel {
	position: absolute;
	top: 0px;
	right: 8px;
	cursor: pointer;
}
.artist_profile .flter-box .clear {
	color: #00bbff;
	background: transparent;
	padding: 0;
	border: 0;
	font-size: 14px;
	font-family: "Vietnam-reg";
}
.artist_profile .flter-box .topbar {
	justify-content: left;
}
.artist_profile .flter-box {
	text-align: left;
	width: 26%;
	position: absolute;
	left: unset;
	right: 9%;
	top: 37%;
}
.artist_profile .flter-box .topbar button {
	border-right: 0;
	padding-right: 0;
}
.artist_profile .flter-box .radio-box .head {
	padding: 0 25px;
}
.artist_profile .flter-box .images-box ul.list li {
	padding-left: 50px;
}
.artist_profile .flter-box .radio-box.tags ul {
	padding: 0 0 0 30px;
	margin: 0;
	list-style: none;
}
.artist_profile .flter-box .radio-box .form-group {
	padding: 0 20px;
}
@media (max-width: 1300px) {
	.artist_profile .flter-box {
		right: 7%;
		top: 49.5%;
	}
}
@media (max-width: 1200px) {
	.artist_profile .flter-box {
		right: 10.3%;
		top: 50.5%;
	}
}
@media (max-width: 1100px) {
	.artist_profile .top-header {
		max-width: 100%;
	}
	.artist_profile .flter-box {
		right: 7.5%;
		top: 47.5%;
	}
}
@media (max-width: 991px) {
	.artist_profile .flter-box {
		right: 14.5%;
		top: 55.5%;
		width: 35%;
	}
	.artist_profile .flter-box .radio-box .head {
		padding: 0 0px;
	}
	.artist_profile .flter-box .radio-box.tags ul {
		padding: 0 0 0 0px;
	}
	.artist_profile .flter-box .radio-box .form-group {
		padding: 0 0px;
	}
}
@media (max-width: 800px) {
	.artist_profile .flter-box {
		right: 6.5%;
		top: 55.5%;
		width: 40%;
	}
}
@media (max-width: 768px) {
	.artist_profile .top-header {
		display: block;
		text-align: center;
	}
	.artist_profile .flter-box {
		right: 4.5%;
		top: 69%;
		width: 60%;
	}
}
@media (max-width: 575px) {
	.artist_profile .flter-box {
		right: 2%;
		top: 68.5%;
		width: 60%;
	}
}
@media (max-width: 414px) {
	.artist_profile .top-header .content_wrapper .uploads {
		font-size: 14px;
	}
	.artist_profile .filter-bar {
		display: block;
	}
	.artist_profile .filter-bar .showing-result {
		padding-bottom: 20px;
		text-align: center;
	}
	.artist_profile .filter-bar .button-group {
		text-align: center;
	}
	.artist_profile .flter-box {
		width: 95%;
		left: 0;
		right: 0;
		margin: 0px auto;
		top: 80%;
	}
}
/* Artist Profile Css End Here */
