section.uploadimage {
	padding: 120px 0px 75px;
	background-image: url(http://localhost:3000/static/media/login-bg.216088d4a229195bee9f.png);
	background-position: top left;
	background-repeat: no-repeat;
}

section.uploadimage .testimonial_start .form_start {
	padding: 35px 58px;
	border: 1px solid #ffffff70;
	border-radius: 6px;
}

.form_start .form-control {
	height: 47px;
	border-radius: 4px;
	background: #141737;
	color: var(--extra-color-4);
	font-family: var(--font-light);
	font-size: 12px;
	margin-bottom: 20px;
	/* text-transform: capitalize; */
}

section.uploadimage .testimonial_start .form_start select {
	text-transform: capitalize;
}

input[type="date"]::-webkit-calendar-picker-indicator {
	filter: invert(1);
}

.form_start .form-group .form-check input[type="radio"] {
	border-radius: 50%;
	height: 20px;
}

.form_start .form-check-input:checked[type="radio"] {
	background: none;
}
.form_start .form-check-input:checked[type="radio"]::after {
	content: "";
	width: 10px;
	height: 10px;
	background: var(--extra-color-2);
	display: block;
	border-radius: 50%;
	position: relative;
	top: 4px;
	left: 4px;
}
.form_start .form-control::placeholder {
	color: var(--extra-color-4);
}
.form_start .form-group label,
.form_start .form-check label {
	margin-bottom: 10px;
	font-family: var(--font-semibold);
	font-size: 11px;
}
.form_start .form-group {
	margin-bottom: 31px;
}

.form_start .form-group .tags-flex {
	display: flex;
	flex-wrap: wrap;
	gap: 7px 14px;
}

.form_start .form-group .tags-flex button.single_tag {
	background: #141737;
	color: var(--button-text-color);
	position: relative;
	padding: 4px 25px 8px;
	border: 1px solid #cecece;
}

.form_start .form-group .tags-flex button.single_tag span.tag-name {
	font-size: 10px;
	font-family: var(--font-light);
}

.form_start .form-group .tags-flex button.single_tag span.cross {
	font-size: 6px;
	font-family: var(--font-semibold);
	position: absolute;
	top: 3px;
	right: 10px;
	cursor: pointer;
}

.form_start .form-group .upload-form {
	display: flex;
	justify-content: space-between;
}

.form_start .form-group .upload-form .learn-more {
	color: var(--extra-color-2);
	font-size: 12px;
	font-family: var(--font-semibol);
}

.form-group .form-check label {
	font-family: var(--font-default);
	font-size: 10px;
	font-weight: 400;
	margin-left: 10px;
	margin-bottom: 0px;
}

.form-group .form-check input {
	background-color: var(--extra-color-1);
	border: 1px solid var(--extra-color-2);
	border-radius: 0px;
	width: 20px;
}

.form-group .form-check.read input {
	border: 1px solid #98a1bc;
}

.form-check.read label {
	font-size: 13px;
	font-weight: 400;
}
.form_start .form-group .include-people {
	display: flex;
	align-items: center;
}
.form_start .form-group .include-people span.first {
	width: 301px;
	display: block;
}

.form_start .form-group .include-people .checkboxes {
	display: flex;
	padding-right: 35px;
	gap: 25px;
}

.form_start .form-group .include-people span {
	font-size: 14px;
	color: var(--extra-color-4);
}
span.file-types {
	display: none;
}

.form_start .form-group label.sc-bcXHqe svg {
	display: none;
}
.sc-dkrFOg {
	justify-content: center !important;
}
label.sc-bcXHqe {
	max-width: 100%;
	border: 1px dashed #fff9;
	height: 110px;
}

.dmajdp > span {
	color: var(--extra-color-4);
	font-family: var(--font-default);
}
.file_upload_system {
	position: relative;
}

.file_upload_system .clip_img {
	position: absolute;
	top: 53%;
	left: 33%;
}

.file_upload_system .clip_imgset {
	position: absolute;
	top: 53%;
	left: 29%;
}

.file_upload_system .clip_img img {
	max-width: 25px;
}
section.uploadimage .testimonial_start .form_start ul.terms-lines {
	padding-left: 5px;
}

section.uploadimage .testimonial_start .form_start ul.terms-lines li {
	list-style: none;
	padding-left: 23px;
}
section.uploadimage .testimonial_start .form_start ul.terms-lines li::before {
	content: "";
	position: absolute;
	display: block;
	left: 0px;
	top: 1px;
	width: 11px;
	height: 11px;
	background-image: url("../img/list.png");
	border-radius: 50%;
	background-size: 100%;
}
.basic-multi-select .css-t3ipsp-control {
	background: #141737;
}
.basic-multi-select #react-select-5-listbox,
.basic-multi-select #react-select-3-listbox {
	background: #141737;
	color: #fff;
}
.basic-multi-select .select__control.css-13cymwt-control {
	height: 47px;
	border-radius: 4px;
	background: #141737;
	color: var(--extra-color-4);
	font-family: var(--font-light);
	font-size: 12px;
	margin-bottom: 20px;
}
.uploadimage .basic-multi-select .select__multi-value__remove {
	background: var(--extra-color-2);
}
section.uploadimage .testimonial_start .form_start ul.terms-lines li {
	position: relative;
	font-size: 10px;
	font-family: var(--font-default);
	color: var(--extra-color-4);
	margin-top: 9px;
}
section.uploadimage .testimonial_start .form_start ul.terms-lines li a {
	font-size: 10px;
	font-family: var(--font-default);
	color: var(--extra-color-2);
}

.form_start .form-group .form-submit-terms {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form_start .form-group .form-submit-terms button.btn.submit_upload_form {
	padding: 8px 37px;
	font-size: 12px;
}

.form_start .form-group .form-submit-terms .form-check input {
	width: 17px;
}

.form_start .form-group .form-submit-terms .form-check {
	display: flex;
	align-items: end;
}

.form_start .form-group .form-submit-terms .form-check label {
	cursor: pointer;
}
section.uploadimage .testimonial_start .form_start ul.terms-lines li a {
	font-size: 10px;
	font-family: var(--font-default);
}
/* @media (max-width: 1280px) {
  .file_upload_system .clip_img {
    left: 29%;
  }
} */

label.sc-bcXHqe span {
	display: none;
}

.file_upload_system .clip_imgset {
	display: none;
}
.file_wrapper {
	position: relative;
}

.file_wrapper .err {
	position: absolute;
	top: 0;
	height: 100%;
	margin: 0px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	z-index: -99;
}

.file_wrapper .err ruby {
	margin-left: 10px;
	color: #56c7f6;
	font-size: 13px;
	font-family: "Vietnam-reg";
}
/* responsive starts here */
@media (max-width: 1024px) {
	.file_upload_system .clip_img {
		left: 31%;
	}
}

@media (max-width: 991px) {
	.dmajdp > span {
		margin-left: 30px;
	}
}

@media (max-width: 768px) {
	.file_upload_system .clip_img {
		left: 26%;
		position: absolute;
		top: 53%;
	}
	.file_upload_system .clip_imgset {
		left: 19%;
	}
}

@media (max-width: 575px) {
	.dmajdp > span {
		font-size: 11px;
		margin-left: 30px;
	}
	label.sc-bcXHqe {
		min-width: unset;
	}
	section.uploadimage .testimonial_start .form_start {
		padding: 35px 18px;
	}
	.form_start .form-group .form-submit-terms {
		flex-wrap: wrap;
	}
}

@media (max-width: 480px) {
	.form_start .form-group .include-people {
		flex-wrap: wrap;
	}
	.form-group label span.sc-hLBbgP span {
		margin-right: 0px;
	}
	.file_upload_system .clip_img {
		left: 32%;
		position: absolute;
		top: 53%;
	}
	label.sc-bcXHqe {
		min-width: unset;
	}
}
@media (max-width: 375px) {
	.form-group label span.sc-hLBbgP span {
		margin-right: 2px;
	}
	.sc-dkrFOg {
		justify-content: center !important;
		max-width: 232px;
	}
	.file_upload_system .clip_img {
		left: 72%;
	}
	.file_upload_system .clip_imgset {
		left: 8%;
	}
	.dmajdp > span {
		font-size: 11px !important;
		margin-left: 30px;
	}
}
@media (max-width: 320px) {
	label.sc-bcXHqe {
		min-width: 261px;
	}
	.file_upload_system .clip_img {
		left: 87%;
	}
}

@media (max-width: 280px) {
	.dmajdp > span {
		font-size: 9px !important;
	}

	label.sc-bcXHqe {
		min-width: unset;
	}
	section.uploadimage .testimonial_start .form_start {
		padding: 65px 18px 20px;
	}
}
